export const exploreBannerArtworks = [
  {
    alt: 'inward-the-observer',
    src: {
      src: '/images/inward-the-observer.jpg'
    }
  },
  {
    alt: 'maya_bg',
    src: {
      src: '/images/maya_bg.jpg',
    },
  },
  {
    alt: 'bifrost_final_banner',
    src: {
      src: '/images/bifrost_final_banner.jpg',
    },
  },
  {
    alt: 'artwork1',
    src: {
      src: '/images/artwork1.jpg',
    },
  },
  {
    alt: 'maya',
    src: {
      src: '/images/maya.jpg',
    },
  },
];
