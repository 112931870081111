import { getImageSrc } from '@src/utils/getImageSrc';

export function dataToGroupBoxViewModel(data) {
  return {
    groupTitle: data.name,
    imageGroupIcon: getImageSrc(data.icon, 'group_icon', data.title),
    imageGroupHead: {
      ...getImageSrc(data.header_image, 'group_header_medium', data.name),
      url: `/community/groups/${data.slug}`,
    },

    inSlider: true,
    groupId: data.id,
  };
}
