import { useQuery } from '@apollo/client';
import { Heading } from '@components/atoms/heading/Heading';
import Loader from '@components/atoms/loader/Loader';
import SEO from '@components/atoms/seo/Seo';
import Wysiwyg from '@components/atoms/wysiwyg/Wysiwyg';
import Layout from '@components/organisms/layout/Layout';
import { styled } from '@root/stitches.config';
import { CMS_GET_PAGE_DATA } from '@src/apollo/query';
import React, { useEffect, useState } from 'react';

const About = context => {
  const [pageState, setPageState] = useState({
    title: '',
    wysiwyg: '',
    isDataSet: false,
  });

  const { data: dataFromCMS } = useQuery(CMS_GET_PAGE_DATA, {
    context: { clientName: 'public' },
    variables: {
      slug: context.path.substring(1),
    },
  });

  useEffect(() => {
    dataFromCMS
      ? setPageState({
          title: dataFromCMS.page.title,
          wysiwyg: dataFromCMS.page.wysiwyg,
          isDataSet: true,
        })
      : null;
  }, [dataFromCMS]);

  return (
    <Layout
      location={context.location}
      seomatic={context?.pageContext?.entry?.seomatic}
    >
      <SEO slug="about" title="About" image={dataFromCMS?.page?.seo?.image} />
      <WrapperPage>
        <HeadingStyled level="h1">{pageState.title}</HeadingStyled>
        {pageState.isDataSet ? (
          <Wysiwyg align="left" fontSize="medium" data={pageState.wysiwyg} />
        ) : (
          <Loader isLoading={true} />
        )}
      </WrapperPage>
    </Layout>
  );
};
export default About;

const WrapperPage = styled('div', {
  minHeight: '30vh',
  maxWidth: '1100px',
  padding: '$48 $24',
  margin: '0 auto',
  justifyContent: 'center',
  '@bp2': {
    padding: '$48 0',
  },
});

const HeadingStyled = styled(Heading, {
  marginLeft: 'auto',

  '@bp2': {
    marginLeft: '10%',
  },
});
