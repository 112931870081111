import { styled, keyframes } from '@root/stitches.config';

const scrollLeft = keyframes({
  '0%': {
    transform: 'translateX(0%)',
  },
  '100%': {
    transform: 'translateX(-60%)',
  },
});

export const ChildrenWrapper = styled('div', {
  whiteSpace: 'nowrap',
  display: 'flex',
  width: 'fit-content',
  fontFamily: '$ArtifaktLegendExtraBold',
  position: 'relative',
  lineHeight: '$1',
  transform: 'translateX(-50%)',
  left: '50%',
  '.child': {
    color: '$mainTextColor',
    margin: '0 24px',
    '@bp2': {
      margin: '0 64px',
    },
  },
  variants: {
    variant: {
      small: {
        fontSize: '$20',
      },
      medium: {
        h1: {
          fontSize: '$60',
        },
      },
      large: {
        fontSize: '$92',
      },
      hero: {
        fontSize: '$32',
        lineHeight: '$1',
        h1: {
          fontSize: '$60',
        },
        '@bp2': {
          fontSize: '$92',
          lineHeight: '130px',
          letterSpacing: '-0.02em',
          h1: {
            fontSize: '130px',
          },
        },
      },
    },
    isAnimated: {
      true: {
        animation: `${scrollLeft} 10s linear infinite`,
        transform: 'translateX(0)',
        left: '0',
      },
    },
  },
});

export const Wrapper = styled('div', {
  position: 'relative',
});

export default null;
