import Button from '@components/atoms/button/Button';
import { ExploreAreaBannerWrapper } from './ExploreAreaBanner.styled';
import Image from '@components/atoms/image/Image';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@components/atoms/text/Text';
import TextMarquee from '@components/molecules/textMarquee/TextMarquee';

const ExploreAreaBanner = props => {
  const { title, content, action, artworks, css } = props;
  return (
    <ExploreAreaBannerWrapper css={css}>
      <div className="explore_area_banner_artworks">
        {artworks.map((artwork, index) => {
          return (
            <div
              className="image-wrapper"
              key={artwork.alt + artwork.src + index}
            >
              <Image src={artwork.src} alt={artwork.alt} />
            </div>
          );
        })}
      </div>
      <TextMarquee
        text={title}
        variant="hero"
        className="explore_area_banner_text_marquee"
        // isAnimated
      />
      <div className="explore_area_banner_content_wrapper">
        <Text as="p" className="explore_area_banner_text">
          {content}
        </Text>
        <Button
          label={action.label}
          internalUrl={action.internalUrl}
          externalUrl={action.externalUrl}
          colorTheme={action.colorTheme}
          onClick={action.onClick}
          actionType={action.actionType}
          svgIcon={action.svgIcon}
          svgLocation={action.svgLocation}
        />
      </div>
    </ExploreAreaBannerWrapper>
  );
};

ExploreAreaBanner.propTypes = {
  title: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  css: PropTypes.object,
  action: PropTypes.shape({
    label: PropTypes.string,
    colorTheme: PropTypes.string,
    internalUrl: PropTypes.string,
    externalUrl: PropTypes.string,
    actionType: PropTypes.oneOf(['onclick', 'externalLink', 'internalLink']),
    onClick: PropTypes.func,
    svgIcon: PropTypes.string,
    svgLocation: PropTypes.oneOf(['before', 'after']),
  }).isRequired,
};

export default ExploreAreaBanner;
