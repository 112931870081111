import React, { useEffect, useState } from 'react';

// import Meta from './sections/Meta';
import Authoring from './sections/Authoring';
import { CreateAdminWrapper } from './CreateAdmin.styled';
import { Heading } from '@components/atoms/heading/Heading';
import PropTypes from 'prop-types';
import PublishOptions from './sections/PublishOptions';
import String from '@components/atoms/string/String';
import { TabNav } from '@components/molecules/tabNav/TabNav';

const navItems = [
  {
    label: 'Publishing options',
    alt: 'Publishing options',
    sectionName: 'publish_options',
  },
  // {
  //   "label":"Meta tags",
  //   "alt":"Meta tags",
  //   "sectionName":"meta",
  // },
  {
    label: 'Authoring',
    alt: 'Authorning',
    sectionName: 'authoring',
  },
];

function CreateAdmin(props) {
  const { createType, createAdminFormState, onFormChange } = props;

  const [createAdminState, setCreateAdminState] = useState();

  const typeNavItems =
    createType !== 'article'
      ? navItems.filter(el => el.sectionName !== 'publish_options')
      : navItems;

  const [currentSection, setCurrentSection] = useState(
    typeNavItems[0].sectionName
  );

  function onChange(sectionType, value) {
    const newState = { ...createAdminState };
    newState[sectionType] = value;
    setCreateAdminState(newState);
    onFormChange(newState);
  }

  useEffect(() => {
    setCreateAdminState({
      ...createAdminState,
      ...createAdminFormState,
    });
  }, [createAdminFormState]);

  const currentSectionComp = {
    publish_options: (
      <PublishOptions
        onChange={onChange}
        value={createAdminFormState.publish_options}
      />
    ),
    // meta: <Meta onChange={onChange} value={createAdminFormState.meta} />,
    authoring: (
      <Authoring onChange={onChange} value={createAdminFormState.authoring} />
    ),
  };

  return (
    <CreateAdminWrapper>
      <Heading level="h3">
        <String id="create_admin_title" />
      </Heading>
      <TabNav
        changeCurrentSection={setCurrentSection}
        currentSection={currentSection}
        tabsItems={typeNavItems}
      />
      {currentSectionComp[currentSection]}
    </CreateAdminWrapper>
  );
}

CreateAdmin.propTypes = {
  createType: PropTypes.oneOf([
    'article',
    'artwork',
    'tutorial',
    'tutorial_series',
    'asset',
  ]),
  createAdminFormState: PropTypes.shape({
    publish_options: PropTypes.object,
    meta: PropTypes.object,
    authoring: PropTypes.object,
  }).isRequired,
  onFormChange: PropTypes.func.isRequired,
};

export default CreateAdmin;
