import {
  AppBannerBtnWrapper,
  AppBannerContentWrapper,
  AppBannerWrapper,
  ButtonStyled,
  HeadingStyled,
  SvgStyled,
  TextStyled,
  StyledAppsLinks,
} from './AppBanner.styled';

import Button from '@components/atoms/button/Button';
import React from 'react';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import Image from '@components/atoms/image/Image';

const AppBanner = props => {
  return (
    <AppBannerWrapper>
      <Image
        src={
          props.appBannerLogo.src
            ? props.appBannerLogo.src.childImageSharp.fixed
            : {}
        }
        alt={props.appBannerLogo.alt}
      />
      <AppBannerContentWrapper>
        <HeadingStyled
          level="h2"
          isBold={true}
          weight="legendBold"
          className="main-heading"
        >
          <String id="app_banner_title" />
        </HeadingStyled>
        <HeadingStyled level="h5">
          <String id="app_banner_text_1" />
        </HeadingStyled>
        <AppBannerBtnWrapper>
          <StyledAppsLinks
            href="https://apps.apple.com/us/app/area-by-autodesk/id1606984540"
            target="_blank"
          >
            <SvgStyled icon={'apple'} color="white" />
            <Text weight="legendBold">
              <String id="get_on_appstore_text" />
            </Text>
          </StyledAppsLinks>
          <StyledAppsLinks
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.autodesk.android.area&hl=en_CA&gl=US"
          >
            <SvgStyled icon={'google-playstore'} />
            <Text weight="legendBold">
              <String id="get_on_playstore_text" />
            </Text>
          </StyledAppsLinks>
        </AppBannerBtnWrapper>
      </AppBannerContentWrapper>
    </AppBannerWrapper>
  );
};

export default AppBanner;

AppBanner.defaultProps = {
  appBannerLogo: {
    alt: 'App Banner Logo',
    src: {
      childImageSharp: {
        fixed: {
          height: 100,
          width: 100,
          src: `/images/app_banner_logo.png`,
          srcSet: `/images/app_banner_logo.png 1x`,
        },
      },
    },
  },
  googlePlayLogo: {
    alt: 'Google Play Logo',
    src: {
      childImageSharp: {
        fixed: {
          height: 24,
          width: 24,
          src: `/images/google_play_logo.png`,
          srcSet: `/images/google_play_logo.png 1x`,
        },
      },
    },
  },
  appleLogo: `apple`,
};
