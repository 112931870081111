import { formatDate } from '@src/utils/formatDate';
import { monthsNames } from '@src/utils/monthNames';
import { validURL } from '@src/utils/validURL';
import { getAvatarImage } from '@src/utils/getAvatarHelper';

export function userContentType(contentType, articleTypeKey) {
  if (
    contentType === 'article' &&
    (articleTypeKey === 'motm' || articleTypeKey === 'moty')
  ) {
    return 'ambassador';
  } else {
    return contentType;
  }
}

export function userContentUrl(
  contentType,
  articleTypeKey,
  isInspireArticle,
  username,
  slug,
  status,
  year,
  isInternalMember
) {
  if (contentType === 'ambassador') {
    return `/inspire/${
      articleTypeKey === 'motm' ? `member-of-month/${year}` : 'member-of-year'
    }/${slug}`;
  }
  if (contentType === 'article' && isInspireArticle) {
    return `/inspire/articles/${slug}`;
  }
  if (isInternalMember) {
    if (contentType === 'tutorial' || contentType === 'asset') {
      return `/learn/${contentType}s/${slug}`;
    }
    if (contentType === 'artwork') {
      return `/inspire/gallery/${slug}`;
    }
    if (contentType === 'article') {
      return `/inspire/articles/${slug}`;
    }
  }
  if (contentType === 'tutorial_series' && username) {
    return `/m/${username}/tutorial_series/${slug}${
      status === 'pending' ? status : ''
    }`;
  } else if (username) {
    return `/m/${username}/${contentType}s/${slug}${
      status === 'pending' ? `/${status}` : ''
    }`;
  }
  return null;
}

export function dataToThumbnailViewModel(
  data,
  hideAuthorData = false,
  isAuthenticated,
  menuActions = []
) {
  const contentType = userContentType(
    data.userContentType,
    data.article_type?.key
  );

  const isMemberOf = contentType === 'ambassador';
  const isInspireArticle = data?.article_section?.key === 'inspire';
  const isInternalMember = data?.creator?.is_internal;
  const contentUrl = userContentUrl(
    contentType,
    data?.article_type?.key,
    isInspireArticle,
    data?.creator?.username,
    data.slug,
    data?.status,
    data?.motm_year,
    isInternalMember
  );

  function dateString() {
    if (isMemberOf) {
      if (data?.article_type?.key === 'motm') {
        return `${monthsNames[data?.motm_month - 1]} ${data?.motm_year}`;
      } else return data?.motm_year;
    }
    return formatDate(data?.publishedAt);
  }

  function profilePicture() {
    const profilePic = getAvatarImage(
      data?.creator?.userProfile?.profile_picture,
      'xsmall'
    );
    if (profilePic && profilePic !== null) {
      return validURL(profilePic) ? profilePic : `${profilePic}`;
    }
    return null;
  }

  return {
    type: contentType,
    tutorialLevel: data?.tutorial_level?.name.toLowerCase(),
    ambassadorType:
      data?.article_type?.key !== 'article'
        ? data?.article_type?.key
        : undefined,
    ...(!hideAuthorData &&
      !isInternalMember && {
        authoring: {
          profilePicture: profilePicture(),
          author: {
            name: `${data?.creator?.first_name} ${data?.creator?.last_name}`,
            slug: data?.creator?.username,
          },
        },
      }),

    ...(menuActions && { menuActions }),
    status: data?.status,
    title: data?.title,
    imageUrl: validURL(data?.image?.path)
      ? data?.image?.path
      : `${CDN_URL}${data?.image?.path}`,
    srcSet: data?.image?.sizes,
    ambassadorTitle: data?.motm_member?.title,
    datePosted: dateString(),
    url: contentUrl,
    id: data?.id,
    seriesCount: (data?.blocs || [])
      .filter(block => block?.type === 'content')
      .map(block => block.contents.length)
      .reduce(function (a, b) {
        return a + b;
      }, 0),
    showLikeButton: isAuthenticated && data?.status !== 'pending',
    isLiked: data?.isLiked,
    isFeatured: data?.isFeatured || data?.feature,
  };
}
