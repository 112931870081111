import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';
import { Link } from 'gatsby';

export const GroupBoxWrapper = styled('div', {
  borderRadius: '$8',
  display: 'inline-block',
  textAlign: 'center',
  position: 'relative',
  minWidth: '160px',
  width: '120px',
  marginBottom: '$32',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '$box',
  border: '1px solid $lightGrey',
  fontFamily: '$ArtifaktLegendBold',
  display: 'block',

  '@bp2': {
    width: '170px',
  },

  // '::before': {
  //   content: '""',
  //   position: 'absolute',
  //   display: 'block',
  //   top: 0,
  //   left: 0,
  //   width: '100%',
  //   height: '100%',
  //   zIndex: 2,
  //   borderRadius: '$8',
  // }

  variants: {
    inListing: {
      true: {
        width: '100%',

        '@bp2': {
          width: '100%',
        },
      },
    },

    inSlider: {
      true: {
        width: '172px',

        '@bp2': {
          width: '172px',
        },
      },
    },
  },

  // ds_clean
  // '.theme-0 &': {
  //   backgroundColor: '$charcoal100',
  // },
});
export const GroupBoxLoadingWrapper = styled('div', {
  borderRadius: '$8',
  display: 'inline-block',
  padding: '$16 $8 $12',
  textAlign: 'center',
  position: 'relative',
  minWidth: '140px',
  width: '120px',
  height: '167px',
  marginBottom: '$32',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '$box',

  '@bp2': {
    width: '170px',
    padding: '$16 $16 $12',
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    display: 'block',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
    border: '1px solid $elements',
    borderRadius: '$8',
  },

  variants: {
    inSlider: {
      true: {
        width: '172px',
        padding: '0',
      },
    },
  },
});
export const HeaderLoading = styled('div', {
  position: 'absolute',
  top: 0,
  left: '0px',
  width: '100%',
  paddingBottom: '41%',
  zIndex: 3,
  borderTopLeftRadius: '$8',
  borderTopRightRadius: '$8',
  overflow: 'hidden',
  backgroundColor: '$elements',
});

export const ImageWrapper = styled('div', {
  position: 'relative',
  top: 0,
  left: '0px',
  width: '100%',
  zIndex: 3,
  borderTopLeftRadius: '$8',
  borderTopRightRadius: '$8',
  overflow: 'hidden',

  img: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    borderTopLeftRadius: '$8',
    borderTopRightRadius: '$8',
  },

  '&::before': {
    content: '""',
    display: 'block',
    paddingBottom: '41%',
  },

  variants: {
    inSlider: {
      true: {
        '&::before': {
          paddingBottom: '32.56%',
        },
      },
    },
  },
});

export const BackgroundImageWrapper = styled('div', {
  position: 'relative',
  top: 0,
  left: '0px',
  width: '100%',
  zIndex: 3,
  borderTopLeftRadius: '$8',
  borderTopRightRadius: '$8',
});

export const IconLoading = styled('div', {
  position: 'relative',
  borderRadius: '$8',
  // overflow: 'hidden',
  display: 'inline-block',
  marginTop: '$8',
  width: '60px',
  height: '60px',
  fontSize: '0',
  zIndex: 5,
  backgroundColor: '$box',
  border: '1px solid $elements',
});

export const IconWrapper = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',

  '&:before': {
    content: '""',
    display: 'block',
    paddingBottom: '100%',
  },

  img: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    objectFit: 'cover',
  },
});

export const IconImage = styled('div', {
  position: 'absolute',
  borderRadius: '$8',
  // overflow: 'hidden',
  display: 'inline-block',
  bottom: '-$24',
  width: '60px',
  height: '60px',
  fontSize: '0',
  zIndex: 5,
  left: '50%',
  transform: 'translateX(-50%)',

  img: {
    width: '100%',
    borderRadius: '$8',
  },

  variants: {
    inListing: {
      true: {
        width: '90px',
        height: '90px',
        left: '$16',
        transform: 'unset',
        filter: 'unset',
      },
    },

    isNew: {
      true: {
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: '-4px',
          right: '-4px',
          width: '12px',
          height: '12px',
          borderRadius: '100%',
          backgroundColor: '$red',
          zIndex: '1',
        },
      },
    },

    inSlider: {
      true: {
        width: '50px',
        height: '50px',
        bottom: '-$16',

        '@bp1': {
          width: '60px',
          height: '60px',
          bottom: '-$24',
        },
      },
    },
  },
});

export const GroupTitle = styled('div', {
  color: '$mainTextColor',
  whiteSpace: 'initial',
  width: '100%',

  variants: {
    inListing: {
      true: {
        textAlign: 'left',
        fontSize: '$24',
        fontFamily: '$ArtifaktLegendBold',
        fontWeight: '700',
        lineHeight: '$2',
        marginBottom: '$8',
      },
    },

    inSlider: {
      true: {
        fontSize: '$14',
        fontFamily: '$ArtifaktLegendBold',
        lineHeight: '$2',

        '@bp1': {
          fontSize: '$16',
        },
      },
    },
  },
});

export const GroupActions = styled('div', {
  position: 'relative',
  width: '100%',
  zIndex: 1,
  // zIndex: 10,
  opacity: '1',
  pointerEvents: 'all',
  alignSelf: 'flex-end',

  variants: {
    isMember: {
      true: {
        opacity: '0',
        pointerEvents: 'none',
      },
      false: {
        opacity: '1',
        pointerEvents: 'all',
      },
    },
  },
});

export const JoinLabel = styled('p', {
  fontWeight: '700',
  fontSize: '$12',
  cursor: 'pointer',

  '@bp2': {
    fontSize: '$14',
  },

  svg: {
    width: '16px',
    height: '16px',
    position: 'relative',
    top: '3px',
    marginRight: '$4',
    transition: 'transform .4s ease',
  },

  '&:hover': {},

  variants: {
    inListing: {
      true: {
        textAlign: 'left',
        marginTop: '$16',
      },
    },

    inSlider: {
      true: {
        margin: '0',
      },
    },
  },
});

export const JoinedLabel = styled('p', {
  fontWeight: '700',
  fontSize: '$14',
  color: '$darkGrey',

  variants: {
    inListing: {
      true: {
        textAlign: 'left',
        marginTop: '$16',
      },
    },

    inSlider: {
      true: {
        margin: '0',
      },
    },
  },
});

export const LineLoading1 = styled('div', {
  display: 'block',
  width: '57px',
  height: '4px',
  borderRadius: '4px',
  backgroundColor: '$elements',
  position: 'absolute',
  bottom: '20%',
});

export const LineLoading2 = styled(LineLoading1, {
  bottom: '32%',
});

export const ContentContainer = styled('div', {
  position: 'relative',
  padding: '$24 $8 $12',
  minHeight: '116px',

  '@bp2': {
    padding: '$40 $16 $16',
  },

  variants: {
    inListing: {
      true: {
        padding: '$32 $8 $40',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '@bp2': {
          padding: '$40 $16 $40',
          // minHeight: '257px',
        },
      },
    },

    inSlider: {
      true: {
        minHeight: '76px',
        display: 'grid',
        gridTemplateRows: '1fr 1fr',
        px: '$8',
        // height: '100%',
        div: {
          width: '100%',
        },

        '@bp2': {
          px: '$16',
        },
      },
    },
  },
});

export const ListingDescription = styled(Text, {
  textAlign: 'left',
  color: '$mainTextColor',
  fontFamily: '$ArtifaktElementRegular',
});

export const StyledLink = styled(Link, {
  textDecoration: 'none',
  color: 'unset',
  width: '100%',
  overflow: 'hidden',
  borderRadius: '$8',
  height: '100%',
  display: 'grid',

  variants: {
    inSlider: {
      true: {
        gridTemplateRows: '70px 1fr',
      },
    },
    inListing: {
      true: {
        '@bp2': {
          gridTemplateRows: '136px 1fr',
        },
      },
    },
  },

  '& .groupbox-header-image': {
    transform: 'scale(1)',
    transition: 'transform .5s ease-out',
  },

  '&:hover': {
    '& .groupbox-header-image': {
      transform: 'scale(1.05)',
    },
  },
});

export const MemberSinceWrapper = styled('div', {
  color: '$darkGrey',
  fontSize: '$12',
  textAlign: 'center',
  fontFamily: '$ArtifaktElementRegular',
  lineHeight: '14px',

  span: {
    display: 'inline-flex',
  },

  variants: {
    inListing: {
      true: {
        marginTop: '$24',
        textAlign: 'left',
      },
    },
  },
});
