import { styled } from '@root/stitches.config';
import { Heading } from '@components/atoms/heading/Heading';
import Button from '@components/atoms/button/Button';

export const CommunityBannerWrapper = styled('div', {
  position: 'relative',
  display: `flex`,
  flexDirection: `column`,
  alignItems: 'center',
  height: `auto`,
  width: `100%`,
  padding: '40px 1px',
  backgroundImage: `url("/images/communityBanner_background.png")`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,

  '@bp2': {
    marginBottom: '220px',
    padding: '54px 1px 160px 1px',
  },
});

export const HeadingStyled = styled(Heading, {
  maxWidth: '800px',
  color: '$white',
  textAlign: 'center',
  lineHeight: '$28',
  fontSize: '$24',
  fontFamily: 'ArtifaktLegendExtraBold',

  '@bp2': {
    lineHeight: '66px',
    fontSize: '60px !important',
  },
});

export const ReverseWrapper = styled('div', {
  height: 'auto',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '@bp2': {
    flexDirection: 'column-reverse',
  },
});

export const ScrollMenuStyles = {
  display: 'flex',
  width: '100%',
  margin: '24px 0 0 0',
  padding: '0 20px',
  gap: '14px',
};

export const GroupBoxWrapper = styled('div', {
  top: '340px',
  width: '100%',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 $54',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '0px',
  },
  '& > div': {
    marginRight: '$32',

    '&:first-of-type': {
      marginLeft: 'auto',
    },
    '&:last-of-type': {
      marginRight: 'auto',
    },
  },
});

export const ButtonStyled = styled(Button, {
  padding: '$14 $20 $14 $20',

  '@bp2': {
    margin: '$24 0 0 0',
  },
});
