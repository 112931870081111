import { navigate } from 'gatsby-link';
import { useEffect, useState } from 'react';
import { validURL } from '@src/utils/validURL';

export const VALID_COVER_IMAGE_FORMATS = [
  'image/jpeg',
  'image/jpg',
  'image/png',
];

// --- UTILS --- START

export const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

export function copyToClipboard(text) {
  navigator.clipboard.writeText(text);
}

export function validImageUrl(imageUrl) {
  if (validURL(imageUrl)) {
    return imageUrl;
  } else if (
    validURL(`${CDN_URL}${imageUrl}`) &&
    (imageUrl !== null || imageUrl !== undefined)
  ) {
    return `${CDN_URL}${imageUrl}`;
  } else {
    return null;
  }
}

export function stateToThumbnailViewModel(createState) {
  return {
    type: createState.createType,
  };
}

export const initialCreateState = authContext => ({
  currentStep: 0,
  steps: ['info', 'editor', 'cover'],
  createType: '',
  isDataSet: false,
  authorPicture: authContext.profilePicture,
  author: `${authContext.firstName} ${authContext.lastName}`,
  blockList: [],
  imageBlockList: [],
  coverImagesBlockList: [],
  currentImageCropIndex: 0,
  maxBlocks: 50,
});

export const initialInfoFormState = {
  duration: '',
  durationIsError: false,
  tutorialTypeOptions: [],
  levelOptions: [],
  selectedLevel: '',
  selectedAssetType: '',
  levelIsError: false,
  selectedTutorialType: '',
  tutorialTypeIsError: false,
  industriesList: [],
  industriesIsError: false,
  assetTypesList: [],
  assetTypesIsError: false,
  industriesIsError: false,
  toolsList: [],
  toolsIsError: false,
  // createTagsList: [],
  title: '',
  titleIsError: false,
};

export const initialAdminFormState = {
  publish_options: {
    articleTypeOptions: [],
    articleSectionOptions: [],
    year: null,
    month: null,
    selectedMoMember: {
      id: null,
      name: null,
    },
    selectedMoMemberIsError: false,
    published: true,
    selectedArticleType: '1',
    selectedArticleSection: null,
  },
  meta: {},
  authoring: {
    author: null,
    publishedDate: new Date(),
  },
};

export const initialThumbnailCropData = {
  image: '',
  cropData: {},
  currentImageCrop: '',
};

// --- UTILS --- END

/* ---------------- */

// --- INFO --- START

export function setData(
  data,
  createState,
  setCreateState,
  createInfoFormState,
  setCreateInfoFormState,
  createAdminFormState,
  setCreateAdminFormState
) {
  const industriesList = [];
  const assetTypesList = [];
  const toolsList = [];
  // const createTagsList = [];

  data.industries.forEach(element => {
    const industry = {
      id: element.id.toString(),
      label: element.name,
      isSelected: false,
    };
    industriesList.push(industry);
  });

  data.tools.forEach(element => {
    const tool = {
      id: element.id.toString(),
      value: element.title,
      name: element.title,
      imageFileName: element.icon,
      isSelected: false,
    };
    toolsList.push(tool);
  });

  data.assetTypes.forEach(element => {
    const types = {
      id: element.id.toString(),
      name: element.name,
      value: element.id.toString(),
      isSelected: false,
    };
    assetTypesList.push(types);
  });

  // data.tags.forEach(element => {
  //   const tag = {
  //     id: element.id.toString(),
  //     value: element.id.toString(),
  //     name: element.name,
  //     isSelected: false,
  //   };
  //   createTagsList.push(tag);
  // });

  const levelOptions = [];
  data.tutorialLevels.forEach(element => {
    const level = {
      id: element.id.toString(),
      value: element.id.toString(),
      name: element.name,
    };
    levelOptions.push(level);
  });

  const tutorialTypeOptions = [];
  data.tutorialTypes.forEach(element => {
    const type = {
      id: element.id.toString(),
      value: element.id.toString(),
      name: element.name,
    };
    tutorialTypeOptions.push(type);
  });

  const articleTypeOptions = [];
  data.articleTypes.forEach(element => {
    const type = {
      id: element.id.toString(),
      value: element.id.toString(),
      name: element.name,
    };
    articleTypeOptions.push(type);
  });

  const articleSectionOptions = [];
  data.articleSections.forEach(element => {
    const section = {
      id: element.id.toString(),
      value: element.id.toString(),
      name: element.name,
    };
    articleSectionOptions.push(section);
  });

  setCreateState({
    ...createState,
    isDataSet: true,
  });

  setCreateInfoFormState({
    ...createInfoFormState,
    industriesList,
    toolsList,
    assetTypesList,
    // createTagsList,
    tutorialTypeOptions,
    levelOptions,
  });

  setCreateAdminFormState({
    ...createAdminFormState,
    publish_options: {
      ...createAdminFormState.publish_options,
      articleSectionOptions,
      articleTypeOptions,
    },
  });
}

// --- INFO --- END

/*------------------*/

// --- FORM NAV --- START

function changeSectionValidation({
  createState,
  createInfoFormState,
  createAdminFormState,
  setCreateInfoFormState,
  setCreateAdminFormState,
  isAdmin,
}) {
  if (createState.steps[createState.currentStep] === 'info') {
    let toolsIsValid;
    let industriesIsValid = false;
    const assetTypesIsValid =
      createInfoFormState.selectedAssetType !== '' &&
      createInfoFormState.selectedAssetType !== null &&
      createInfoFormState.selectedAssetType !== undefined;
    const titleIsValid =
      createInfoFormState.title !== '' &&
      createInfoFormState.title !== null &&
      createInfoFormState.title !== undefined;
    const durationIsValid =
      createInfoFormState.duration !== '' &&
      createInfoFormState.duration !== null &&
      createInfoFormState.duration !== undefined;
    const tutorialTypeIsValid =
      createInfoFormState.selectedTutorialType !== '' &&
      createInfoFormState.selectedTutorialType !== null &&
      createInfoFormState.selectedTutorialType !== undefined;
    const levelIsValid =
      createInfoFormState.selectedLevel !== '' &&
      createInfoFormState.selectedLevel !== null &&
      createInfoFormState.selectedLevel !== undefined;
    const isMemberOf =
      ['2', '3'].indexOf(
        createAdminFormState?.publish_options?.selectedArticleType
      ) !== -1;
    const isValidMember =
      isMemberOf &&
      createAdminFormState?.publish_options?.selectedMoMember !== undefined &&
      createAdminFormState?.publish_options?.selectedMoMember !== '';
    const isValidMonth =
      isMemberOf && createAdminFormState?.publish_options?.month !== undefined;
    const isValidYear =
      isMemberOf &&
      !['', NaN, null].includes(createAdminFormState?.publish_options?.year);

    createInfoFormState.industriesList.forEach(element => {
      if (element.isSelected) {
        industriesIsValid = true;
      }
    });

    createInfoFormState.toolsList.forEach(element => {
      if (element.isSelected) {
        toolsIsValid = true;
      }
    });

    let validationType;

    switch (createState.createType) {
      case 'article':
        validationType =
          industriesIsValid &&
          titleIsValid &&
          toolsIsValid &&
          (isAdmin && isMemberOf ? isValidMember : true) &&
          (isAdmin &&
          createAdminFormState?.publish_options?.selectedArticleType === '2'
            ? isValidMonth && isValidYear
            : true) &&
          (isAdmin &&
          createAdminFormState?.publish_options?.selectedArticleType === '3'
            ? isValidYear
            : true);
        break;
      case 'asset':
        validationType = assetTypesIsValid && titleIsValid && toolsIsValid;
        break;
      case 'tutorial':
        validationType =
          industriesIsValid &&
          titleIsValid &&
          toolsIsValid &&
          tutorialTypeIsValid &&
          levelIsValid &&
          durationIsValid;
        break;

      default:
        validationType = industriesIsValid && titleIsValid && toolsIsValid;
        break;
    }

    if (validationType) {
      return true;
    } else {
      if (setCreateInfoFormState !== undefined) {
        setCreateInfoFormState({
          ...createInfoFormState,
          titleIsError: !titleIsValid,
          industriesIsError: !industriesIsValid,
          toolsIsError: !toolsIsValid,
          assetTypesIsError: !assetTypesIsValid,
          levelIsError: !levelIsValid,
          tutorialTypeIsError: !tutorialTypeIsValid,
          durationIsError: !durationIsValid,
        });
      }
      if (setCreateAdminFormState !== undefined) {
        setCreateAdminFormState({
          ...createAdminFormState,
          publish_options: {
            ...createAdminFormState.publish_options,
            selectedMoMemberIsError: isAdmin && !(isMemberOf && isValidMember),
            motmDateIsError:
              isAdmin &&
              createAdminFormState?.publish_options?.selectedArticleType ===
                '2' &&
              !(isValidMonth && isValidYear),
            motyDateIsError:
              isAdmin &&
              createAdminFormState?.publish_options?.selectedArticleType ===
                '3' &&
              !isValidYear,
          },
        });
      }
      return false;
    }
  }
  return true;
}

export function onNavItemClick(
  index,
  createState,
  createInfoFormState,
  createAdminFormState,
  setCreateState,
  setCreateInfoFormState,
  setCreateAdminFormState,
  isAdmin
) {
  if (
    changeSectionValidation({
      createState,
      createInfoFormState,
      createAdminFormState,
      setCreateInfoFormState,
      setCreateAdminFormState,
      isAdmin,
    })
  ) {
    setCreateState({
      ...createState,
      currentStep: index,
    });
  }
}

export function goToSection(
  direction,
  createState,
  createInfoFormState,
  createAdminFormState,
  setCreateState,
  setCreateInfoFormState,
  setCreateAdminFormState,
  isAdmin
) {
  const sectionValidation = changeSectionValidation({
    createState,
    createInfoFormState,
    createAdminFormState,
    setCreateInfoFormState,
    setCreateAdminFormState,
    isAdmin,
  });

  if (direction === 'next') {
    if (sectionValidation) {
      window.scrollTo(0, 0);
      setCreateState({
        ...createState,
        currentStep: createState.currentStep + 1,
      });
      setCreateInfoFormState({
        ...createInfoFormState,
        titleIsError: false,
        industriesIsError: false,
        toolsIsError: false,
        articleTypeIsError: false,
        articleSectionIsError: false,
        levelIsError: false,
        tutorialTypeIsError: false,
        durationIsError: false,
      });
    }
  } else if (direction === 'prev') {
    if (sectionValidation) {
      window.scrollTo(0, 0);
      setCreateState({
        ...createState,
        currentStep: createState.currentStep - 1,
      });
    }
  }
}

// --- FORM NAV --- END

/*------------------*/

// --- EDITOR --- START

// EDITOR - ADD BLOC
export function addImageBlock(image, createState, setCreateState) {
  const updatedBlockListImage = [...createState.imageBlockList];
  const newImageBlock = { data: image };
  if (createState.isCropUploaded) {
    updatedBlockListImage.splice(
      updatedBlockListImage.length - 1,
      1,
      newImageBlock
    );
  } else {
    updatedBlockListImage.push(newImageBlock);
  }

  const coverImagesBlockList = updatedBlockListImage.filter(el =>
    VALID_COVER_IMAGE_FORMATS.includes(el.data.file.type)
  );

  setCreateState({
    ...createState,
    imageBlockList: updatedBlockListImage,
    currentImageCropIndex: coverImagesBlockList.length - 1,
    editPreviousCoverSelected: false,
    coverImagesBlockList,
    isCropUploaded: true,
  });
}

// EDITOR - ADD BLOC
export function onAddBlock(block, createState, setCreateState, coverImage) {
  const newBlock = block;
  const updatedBlockList = [...createState.blockList];

  const existingSlugs = updatedBlockList
    .filter(el => el.slug !== undefined)
    .map(el => {
      return parseInt(el.slug.replace(/\D+/g, ''), 10);
    }); /*  */
  if (updatedBlockList.length === 0) {
    newBlock.slug = `block-0`;
  } else if (
    updatedBlockList.length > 0 &&
    !existingSlugs.includes(updatedBlockList.length)
  ) {
    newBlock.slug = `block-${createState.blockList.length}`;
  } else if (existingSlugs.includes(updatedBlockList.length)) {
    newBlock.slug = `block-${Math.max(...existingSlugs) + 1}`;
  }
  newBlock.isLoading = true;
  updatedBlockList.push(newBlock);

  const updatedBlockListImage = [...updatedBlockList];
  const imageBlocks = updatedBlockListImage.filter(blc => blc.type === 'image');
  const filteredImageBlockList = (!coverImage
    ? imageBlocks
    : [...imageBlocks, coverImage]
  ).filter(el => VALID_COVER_IMAGE_FORMATS.includes(el.data.file.type));

  setCreateState({
    ...createState,
    blockList: updatedBlockList,
    imageBlockList: !coverImage ? imageBlocks : [...imageBlocks, coverImage],
    coverImagesBlockList: filteredImageBlockList,
  });
}

// EDITOR - EDIT VIDEO BLOC
export function onEditVideoBlock(
  blockIndex,
  block,
  createState,
  setCreateState
) {
  const updatedBlockList = [...createState.blockList];
  updatedBlockList[blockIndex].videoUrl = block.data.videoUrl;

  setCreateState({
    ...createState,
    blockList: updatedBlockList,
  });
}

// EDITOR - EDIT SKETCHFAB BLOC
export function onEditSketchfabBlock(
  blockIndex,
  block,
  createState,
  setCreateState
) {
  const updatedBlockList = [...createState.blockList];
  updatedBlockList[blockIndex].sketchfabUrl = block.data.sketchfabUrl;

  setCreateState({
    ...createState,
    blockList: updatedBlockList,
  });
}

// EDITOR - EDIT BLOC
export function onEditBlock(
  blockIndex,
  value,
  createState,
  setCreateState,
  coverImage
) {
  const updatedBlockList = [...createState.blockList];

  updatedBlockList[blockIndex].text = value;
  updatedBlockList[blockIndex].isLoading = true;

  const updatedBlockListImage = [...updatedBlockList];
  const imageBlocks = updatedBlockListImage.filter(
    block => block.type === 'image'
  );
  const filteredImageBlockList = (!coverImage
    ? imageBlocks
    : [...imageBlocks, coverImage]
  ).filter(el => VALID_COVER_IMAGE_FORMATS.includes(el.data.file.type));

  setCreateState({
    ...createState,
    blockList: updatedBlockList,
    imageBlockList: !coverImage ? imageBlocks : [...imageBlocks, coverImage],
    coverImagesBlockList: filteredImageBlockList,
  });
}

// EDITOR - REMOVE BLOC
export function onRemoveBlock(index, createState, setCreateState, coverImage) {
  const updatedBlockList = createState?.blockList
    ? [...createState?.blockList]
    : [];
  updatedBlockList.splice(index, 1);

  const updatedBlockListImage = [...updatedBlockList];
  const imageBlocks = updatedBlockListImage.filter(blc => blc.type === 'image');
  const filteredImageBlockList = (!coverImage
    ? imageBlocks
    : [...imageBlocks, coverImage]
  ).filter(el => VALID_COVER_IMAGE_FORMATS.includes(el.data.file.type));

  setCreateState({
    ...createState,
    blockList: updatedBlockList,
    imageBlockList: !coverImage ? imageBlocks : [...imageBlocks, coverImage],
    coverImagesBlockList: filteredImageBlockList,
  });
}

const moveElement = (array, initialIndex, finalIndex) => {
  array.splice(finalIndex, 0, array.splice(initialIndex, 1)[0]);
  return array;
};

// EDITOR - REORDER BLOCKS W/ BUTTONS
export function onChangeBlockChangeOrder(index, direction, blocklist) {
  const updatedBlockList = blocklist;

  if (direction === 'up' && index > 0) {
    moveElement(updatedBlockList, index, index - 1);
  }

  if (direction === 'down' && index < updatedBlockList.length - 1) {
    moveElement(updatedBlockList, index, index + 1);
  }

  return updatedBlockList.map((el, i) => ({
    ...el,
    order: i,
  }));
}

export function onEditUserContentblock(
  index,
  content,
  createState,
  setCreateState
) {
  const { blockList } = createState;
  const currentBlock = (blockList || []).find(
    (el, i) => i === index && el.type === 'content'
  );

  if (currentBlock) {
    currentBlock.contents = content?.value;
    currentBlock.text = content?.text;

    setCreateState({
      ...createState,
      blockList: [...blockList],
    });
  }
}

// EDITOR - REORDER BLOCKS W/ DRAG N DROP
export function onReOrderBlocks(blockList) {
  const updatedBlockList = [...blockList].map((el, i) => ({
    ...el,
    order: i,
  }));

  return updatedBlockList;
}

// --- EDITOR BLOCKS --- END

/*------------------*/

// --- COVER --- START

export function onChangeImageCropIndex(
  index,
  createState,
  setCreateState,
  isCover
) {
  setCreateState({
    ...createState,
    currentImageCropIndex: index,
    editPreviousCoverSelected: isCover,
  });
}

// --- COVER --- END

/*------------------*/

// --- ON CREATE POST --- START

function publishOptionsToDataModel(adminFormInput) {
  const { publish_options } = adminFormInput;
  const {
    selectedArticleSection,
    selectedArticleType,
    selectedMoMember,
    month,
    year,
  } = publish_options;

  const parsedArtType = parseInt(selectedArticleType, 10);
  const parsedArtSection = parseInt(selectedArticleSection, 10);

  return {
    article_section: parsedArtSection,
    article_type: parsedArtType,
    ...(parsedArtType > 1 && {
      ...(parsedArtType === 2 && {
        motm_month: month,
      }),
      motm_year: year,
      motm_member: selectedMoMember.id,
    }),
  };
}

export function onCreatePostToDataModel({
  createInfoFormState,
  createAdminFormState,
  createState,
  isAdmin,
}) {
  const { title } = createInfoFormState;
  const userContentType = createState.createType;
  const industries = createInfoFormState.industriesList;
  const selectedAssetType = createInfoFormState.selectedAssetType;
  // const tags = createInfoFormState.createTagsList;
  const tools = createInfoFormState.toolsList;
  const blocks = createState.blockList;

  // TEMP
  const industriesArray = [];
  for (let index = 0; index < industries.length; index += 1) {
    const element = industries[index];
    if (element.isSelected) {
      industriesArray.push(parseInt(element.id, 10));
    }
  }

  // const tagsArray = [];
  // for (let index = 0; index < tags.length; index += 1) {
  //   const element = tags[index];
  //   if (element.isSelected) {
  //     tagsArray.push(parseInt(element.id, 10));
  //   }
  // }

  const toolsArray = [];
  for (let index = 0; index < tools.length; index += 1) {
    const element = tools[index];
    if (element.isSelected) {
      toolsArray.push(parseInt(element.id, 10));
    }
  }

  const updatedBlocks = blocks.map(element => {
    const updatedBlock = {};
    updatedBlock.slug = element.slug;
    updatedBlock.type = element.type;
    updatedBlock.order = 0;

    if (element.asset) {
      updatedBlock.asset = element.asset;
    }

    if (element.image) {
      updatedBlock.image = element.image;
    }

    if (element.text) {
      updatedBlock.text = element.text;
    }

    if (element.videoUrl) {
      updatedBlock.videoUrl = element.videoUrl;
    }

    if (element.sketchfabUrl) {
      updatedBlock.sketchfabUrl = element.sketchfabUrl;
    }

    return updatedBlock;
  });

  const finalData = {
    userInput: {
      title,
      userContentType,
      ...(createState.createType !== 'asset'
        ? { industries: industriesArray }
        : {}),
      // tags: tagsArray,
      blocs: updatedBlocks,
      tools: toolsArray,
    },
  };

  if (createState.createType === 'tutorial') {
    finalData.userInput.tutorial_level = parseInt(
      createInfoFormState.selectedLevel,
      10
    );
    finalData.userInput.tutorial_type = parseInt(
      createInfoFormState.selectedTutorialType,
      10
    );
    finalData.userInput.tutorial_duration = parseInt(
      createInfoFormState.duration,
      10
    );
  }

  if (createState.createType === 'asset') {
    finalData.userInput.asset_type = parseInt(
      createInfoFormState.selectedAssetType,
      10
    );
  }

  if (createState.createType === 'article' && isAdmin) {
    finalData.adminInput = publishOptionsToDataModel(createAdminFormState);
  }

  if (isAdmin) {
    finalData.adminInput = {
      ...finalData?.adminInput,
      ...(createAdminFormState.authoring.author !== null && {
        author: createAdminFormState.authoring.author,
      }),
      publication_date: (
        new Date(createAdminFormState.authoring.publishedDate).valueOf() / 1000
      ).toFixed(0),
    };
  }

  return finalData;
}

// --- ON CREATE POST --- END

/*------------------*/

// --- ON UPDATE POST --- START
export function onUpdatePostToDataModel({ blocks }) {
  const updatedBlocks = blocks.map(element => {
    const updatedBlock = {};
    updatedBlock.order = element.order;
    updatedBlock.id = element.id;
    updatedBlock.type = element.type;
    updatedBlock.slug = element.slug;

    if (element.image) {
      updatedBlock.image = element.image;
    }

    if (element.asset) {
      updatedBlock.asset = element.asset;
    }

    if (
      element.id &&
      (element.image !== null || element.image !== undefined) &&
      !element.image?.data_url
    ) {
      delete updatedBlock.image;
    }

    if (element.text) {
      updatedBlock.text = element.text;
    }

    if (element.videoUrl) {
      updatedBlock.videoUrl = element.videoUrl;
    }

    if (element.sketchfabUrl) {
      updatedBlock.sketchfabUrl = element.sketchfabUrl;
    }

    return updatedBlock;
  });

  const finalData = {
    status: 'draft',
    blocs: updatedBlocks,
  };

  return finalData;
}

// --- RESET STATE --- START

export function warningLeaveMessage(setModalData, createType, path) {
  setModalData({
    isShowing: true,
    modalType: 'leaving_create',
    createType: createType,
    actions: [
      {
        onClick: () => {
          setModalData({ isShowing: false });
        },
        colorTheme: 'gray',
      },
      {
        onClick: () => {
          setModalData({ isShowing: false });
          navigate(path);
        },
        colorTheme: 'normal',
      },
    ],
  });
}

// --- RESET STATE --- END

/*------------------*/

// --- ON UPDATE POST --- END

/*------------------*/

// --- PUBLISH --- START
export function createViewToDataModel({
  createInfoFormState,
  createState,
  createAdminFormState,
  thumbnailCropData,
  isSameCover,
  isDraft,
  isAdmin,
}) {
  const { title } = createInfoFormState;
  const industries = createInfoFormState.industriesList;
  // const tags = createInfoFormState.createTagsList;
  const tools = createInfoFormState.toolsList;
  const cropImageFile = thumbnailCropData.currentImageCrop.file;
  const cropImageCopy = thumbnailCropData.cropData;
  const hasCropImageCopy = cropImageCopy
    ? Object.keys(cropImageCopy).length >= 1
    : false;

  if (hasCropImageCopy) {
    cropImageCopy.image = new File([cropImageFile], cropImageFile.name);
  }

  // TEMP
  const industriesArray = [];
  for (let index = 0; index < industries.length; index += 1) {
    const element = industries[index];
    if (element.isSelected) {
      industriesArray.push(parseInt(element.id, 10));
    }
  }

  // const tagsArray = [];
  // for (let index = 0; index < tags.length; index += 1) {
  //   const element = tags[index];
  //   if (element.isSelected) {
  //     tagsArray.push(parseInt(element.id, 10));
  //   }
  // }

  const toolsArray = [];
  for (let index = 0; index < tools.length; index += 1) {
    const element = tools[index];
    if (element.isSelected) {
      toolsArray.push(parseInt(element.id, 10));
    }
  }

  const finalData = {
    userInput: {
      status: !isDraft ? 'publish' : 'draft',
      title,
      ...(createState.createType !== 'asset'
        ? { industries: industriesArray }
        : {}),
      // tags: tagsArray,
      image: cropImageCopy,
      tools: toolsArray,
    },
  };

  if (createState.createType === 'tutorial') {
    finalData.userInput.tutorial_level = parseInt(
      createInfoFormState.selectedLevel,
      10
    );
    finalData.userInput.tutorial_type = parseInt(
      createInfoFormState.selectedTutorialType,
      10
    );
    finalData.userInput.tutorial_duration = parseInt(
      createInfoFormState.duration,
      10
    );
  }

  if (createState.createType === 'asset') {
    finalData.userInput.asset_type = parseInt(
      createInfoFormState.selectedAssetType,
      10
    );
  }

  if (createState.createType === 'article' && isAdmin) {
    finalData.adminInput = publishOptionsToDataModel(createAdminFormState);
  }

  if (isAdmin) {
    finalData.adminInput = {
      ...finalData?.adminInput,
      ...(createAdminFormState.authoring.author !== null && {
        author: createAdminFormState.authoring.author,
      }),
      publication_date: (
        new Date(createAdminFormState.authoring.publishedDate).valueOf() / 1000
      ).toFixed(0),
    };
  }

  if (isDraft || isSameCover || !hasCropImageCopy) {
    delete finalData.userInput.image;
  }

  return finalData;
}

// --- PUBLISH --- END

/* ----------------- */

// --- HOOKS --- START

// EDITOR - DISABLE NEXT BUTTON HOOK
function disabledNextButtonContent(type) {
  switch (type) {
    case type:
      return `create_disabled_next_button_${type}`;
    default:
      return '';
  }
}

export function useCreateNextButtonValidation(
  createState,
  currentStepName,
  createInfoFormState,
  createAdminFormState,
  isAdmin,
  hasCoverImage
) {
  const [hasBlocks, setHasBlocks] = useState(false);
  const [hasImageBlocks, setHasImageBlocks] = useState(false);
  const [hasCoverImagesBlocks, setHasCoverImagesBlocks] = useState(false);
  const [hasTextBlocks, setHasTextBlocks] = useState(false);
  const [hasCover, setHasCover] = useState(false);
  const [hasVideoBlocks, setHasVideoBlocks] = useState(false);
  const [hasSketchfabBlocks, setHasSketchfabBlocks] = useState(false);
  const [hasUserContentBlocks, setHasUserContentBlocks] = useState(false);
  const [blockTypes, setBlockTypes] = useState([]);
  const [isArtworkDisabled, setIsArtworkDisabled] = useState(false);
  const [isTutoSeriesDisabled, setIsTutoSeriesDisabled] = useState(false);
  const [isOtherTypeDisabled, setIsOtherTypeDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [
    hasTextBlockWithEmptyContent,
    setHasTextBlockWithEmptyContent,
  ] = useState(false);
  const [
    hasUserContentBlocksWithEmptyContent,
    setHasUserContentBlocksWithEmptyContent,
  ] = useState(false);
  const [emptyBlockIndexes, setEmptyBlockIndexes] = useState([]);
  const [emptyTextBlockIndexes, setEmptyTextBlockIndexes] = useState([]);
  const [
    emptyUserContentBlockIndexes,
    setEmptyUserContentBlockIndexes,
  ] = useState([]);

  const sectionValidation = changeSectionValidation({
    createState,
    createInfoFormState,
    createAdminFormState,
    isAdmin,
  });

  useEffect(() => {
    if (createState.blockList?.length >= 1) {
      setHasBlocks(true);
    } else {
      setHasBlocks(false);
    }
  }, [createState]);

  useEffect(() => {
    if (hasBlocks) {
      setBlockTypes(createState.blockList?.map(el => el.type));
    }
  }, [hasBlocks, createState]);

  useEffect(() => {
    if (blockTypes?.length >= 1) {
      setHasImageBlocks(blockTypes.includes('image'));
      setHasCoverImagesBlocks(createState.coverImagesBlockList.length >= 1);
      setHasTextBlocks(blockTypes.includes('text'));
      setHasVideoBlocks(blockTypes.includes('video'));
      setHasSketchfabBlocks(blockTypes.includes('sketchfab'));
      setHasUserContentBlocks(blockTypes.includes('content'));
    }
  }, [blockTypes, createState, hasBlocks]);

  useEffect(() => {
    if (
      currentStepName === 'cover' &&
      (createState.imageBlockList.length === 0 || hasCoverImage === false)
    ) {
      setHasCover(false);
    } else if (
      currentStepName === 'cover' &&
      createState.imageBlockList.length >= 1
    ) {
      setHasCover(true);
    }
  }, [createState.imageBlockList, hasCoverImage, currentStepName]);

  useEffect(() => {
    if (hasTextBlocks && hasBlocks && currentStepName === 'editor') {
      const emptyTextBlocks = createState.blockList
        .map((el, i) => {
          return { ...el, index: i };
        })
        .filter(
          el =>
            el.type === 'text' &&
            el.text !== null &&
            el.text
              .replace(/<[^>]*>/g, ' ')
              .replace(/\s{2,}/g, ' ')
              .trim().length <= 1
        )
        .map(el => el.index);

      if (emptyTextBlocks.length > 0) {
        setHasTextBlockWithEmptyContent(true);
        setEmptyTextBlockIndexes(emptyTextBlocks);
      } else {
        setHasTextBlockWithEmptyContent(false);
        setEmptyTextBlockIndexes([]);
      }
    }
  }, [hasBlocks, hasTextBlocks, createState]);

  useEffect(() => {
    if (hasUserContentBlocks && hasBlocks) {
      const emptyUserContentBlocks = (createState.blockList || [])
        .map((el, i) => ({ ...el, index: i }))
        .filter(el => el.type === 'content' && (el.contents || []).length < 1)
        .map(el => el.index);

      if (emptyUserContentBlocks.length > 0) {
        setHasUserContentBlocksWithEmptyContent(true);
        setEmptyUserContentBlockIndexes(emptyUserContentBlocks);
      } else {
        setHasUserContentBlocksWithEmptyContent(false);
        setEmptyUserContentBlockIndexes([]);
      }
    }
  }, [hasBlocks, hasUserContentBlocks, createState]);

  useEffect(() => {
    setEmptyBlockIndexes([
      ...emptyTextBlockIndexes,
      ...emptyUserContentBlockIndexes,
    ]);
  }, [emptyTextBlockIndexes, emptyUserContentBlockIndexes]);

  useEffect(() => {
    const { createType } = createState;

    if (currentStepName === 'editor') {
      if (createType === 'artwork' && !(hasImageBlocks || hasVideoBlocks)) {
        setIsArtworkDisabled(true);
      } else if (
        (createType === 'tutorial' || createType === 'article') &&
        !hasBlocks
      ) {
        setIsOtherTypeDisabled(true);
      } else if (
        createType === 'tutorial_series' &&
        (!hasUserContentBlocks || hasUserContentBlocksWithEmptyContent)
      ) {
        setIsTutoSeriesDisabled(true);
      } else if (createState?.assetBlockHasError) {
        setIsOtherTypeDisabled(true);
      } else {
        setIsArtworkDisabled(false);
        setIsOtherTypeDisabled(false);
        setIsTutoSeriesDisabled(false);
      }
    }
  }, [
    createState,
    hasBlocks,
    blockTypes,
    hasBlocks,
    hasTextBlocks,
    hasVideoBlocks,
    hasSketchfabBlocks,
    hasImageBlocks,
    hasUserContentBlocks,
    hasUserContentBlocksWithEmptyContent,
  ]);

  useEffect(() => {
    if (currentStepName === 'editor') {
      if (
        isArtworkDisabled ||
        isOtherTypeDisabled ||
        isTutoSeriesDisabled ||
        !hasBlocks
      ) {
        setIsNextDisabled(true);
      } else {
        setIsNextDisabled(false);
      }
      if (hasTextBlockWithEmptyContent) {
        setIsNextDisabled(true);
      }
      if (hasUserContentBlocksWithEmptyContent) {
        setIsNextDisabled(true);
      }
    } else if (currentStepName === 'info' && !sectionValidation) {
      setIsNextDisabled(true);
    } else if (
      currentStepName === 'cover' &&
      (!hasCover || !hasCoverImagesBlocks)
    ) {
      setIsNextDisabled(true);
    } else {
      setIsNextDisabled(false);
    }
  }, [
    currentStepName,
    isArtworkDisabled,
    isTutoSeriesDisabled,
    isOtherTypeDisabled,
    sectionValidation,
    hasTextBlockWithEmptyContent,
    hasUserContentBlocksWithEmptyContent,
    hasBlocks,
    hasCover,
    hasUserContentBlocks,
    hasImageBlocks,
    hasCoverImagesBlocks,
  ]);

  const whyDisabled = () => {
    if (isArtworkDisabled && createState.createType === 'artwork') {
      return 'artwork';
    }
    if (currentStepName === 'editor') {
      if (
        createState.createType === 'tutorial_series' &&
        isTutoSeriesDisabled
      ) {
        return 'tutorial_series';
      }
      if (
        createState.createType === 'asset' &&
        createState.blockList.length === 0 &&
        !createState?.assetBlockHasError
      ) {
        return 'asset_no_blocks';
      }
      if (createState?.assetBlockHasError) {
        return 'asset_block_error';
      }
    }
    if (hasTextBlockWithEmptyContent && hasUserContentBlocksWithEmptyContent) {
      return 'empty_blocks';
    }
    if (!sectionValidation) {
      return 'empty_info_inputs';
    }
    if (hasTextBlockWithEmptyContent) {
      return 'empty_text_block';
    }
    if (hasUserContentBlocksWithEmptyContent) {
      return 'empty_user_content_block';
    }
    if (currentStepName === 'cover' && (!hasCover || !hasCoverImagesBlocks)) {
      return 'no_cover';
    }
    if (!hasBlocks) {
      return 'no_blocks';
    }
    return undefined;
  };

  return {
    isNextDisabled,
    content: whyDisabled() && disabledNextButtonContent(whyDisabled()),
    emptyBlockIndexes,
  };
}

export function useShowAdminSettings(authenticated, role) {
  const [showAdminSettings, setShowAdminSettings] = useState(false);

  useEffect(() => {
    if (authenticated && ['admin', 'editor'].indexOf(role) > -1) {
      setShowAdminSettings(true);
    } else {
      setShowAdminSettings(false);
    }
  }, [authenticated, role]);

  return showAdminSettings;
}

// --- HOOKS --- END
