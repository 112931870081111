import {
  ButtonStyled,
  CommunityBannerWrapper,
  GroupBoxWrapper,
  HeadingStyled,
  ScrollMenuStyles,
} from './CommunityBanner.styled';
import React, { useContext, useEffect, useState } from 'react';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import GroupBox from '@components/molecules/groupBox/GroupBox';
import { JOIN_GROUPS } from '@src/apollo/mutation';
import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';
import UrlsContext from '@context/UrlsContext';
import { useMutation, useQuery } from '@apollo/client';
import CommunityFeedContext from '@context/CommunityFeedContext';
import { isGroupMember } from '@src/utils/getMemberGroupInfo';
import { GET_MY_GROUPS } from '@src/apollo/query';
const CommunityBanner = props => {
  const { groupList, isLogged } = props;
  const [breakpoint, setBreakpoint] = useState(window.innerWidth >= 900);
  const [communityState, setCommunityState] = useState({
    myGroupsId: [],
  });
  const handleResize = () => {
    window.innerWidth >= 900 ? setBreakpoint(true) : setBreakpoint(false);
  };

  const {
    loading: loadingMyGroups,
    error: errorMyGroups,
    data: dataMyGroups,
  } = useQuery(GET_MY_GROUPS, {
    notifyOnNetworkStatusChange: true,
    skip: !isLogged,
  });

  useEffect(() => {
    if (dataMyGroups) {
      const groupsIdList = [];
      dataMyGroups.mygroups.map((group, i) => {
        groupsIdList.push(group.id);
      });
      if (isLogged) {
        setCommunityState({
          myGroupsId: groupsIdList,
        });
      }
    }
  }, [dataMyGroups]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const UrlContext = useContext(UrlsContext);

  // const [joinGroups, { dataGroups }] = useMutation(JOIN_GROUPS);

  const onJoinGroup = id => {
    const newArray = communityState.myGroupsId;

    if (!newArray.indexOf(id)) {
      newArray.push(id);
    }

    newArray.push(id);
    setCommunityState({
      ...communityState,
      myGroupsId: newArray,
    });
  };

  return (
    <CommunityFeedContext.Provider value={[communityState, setCommunityState]}>
      <CommunityBannerWrapper>
        <HeadingStyled level="h2" isBold={true}>
          Introducing <br />
          Groups and Conversations
        </HeadingStyled>
        {breakpoint ? (
          <GroupBoxWrapper>
            {groupList.map((groupBox, i) => {
              return (
                <GroupBox
                  {...groupBox}
                  key={i}
                  isLogged={isLogged}
                  myGroupsId={communityState.myGroupsIds}
                  onJoinGroup={onJoinGroup}
                />
              );
            })}
          </GroupBoxWrapper>
        ) : (
          <ScrollContainer
            horizontal={true}
            vertical={false}
            children={groupList.map((groupBox, i) => {
              return (
                <GroupBox {...groupBox} key={i} isLogged={props.isLogged} />
              );
            })}
            style={ScrollMenuStyles}
            // itemStyle={ScrollMenuStyles.itemsStyles}
            // menuStyle={ScrollMenuStyles.menu}
            // scrollToSelected={true}
            // translate={0}
            // transition={+0.3}
            // data={groupList.map((groupBox, i) => {
            //   return <GroupBox {...groupBox} key={i} isLogged={props.isLogged} />;
            // })}
          />
        )}
        <ButtonStyled
          type="normal"
          colorTheme="white"
          actionType="internalLink"
          internalUrl={GetUrl('community', UrlContext)}
        >
          Explore community
        </ButtonStyled>
      </CommunityBannerWrapper>
    </CommunityFeedContext.Provider>
  );
};

CommunityBanner.prototype = {
  groupList: PropTypes.array.isRequired,
};

export default CommunityBanner;
