import React, { useEffect, useState } from 'react';

import { InputDropdownSearchWrapper } from './InputDropdownSearch.styled';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import './InputDropdownSearch.css';


const InputDropdownSearch = props => {
  const [inputState, setInputState] = useState();

  useEffect(() => {
    if(props.value){
      setInputState(props.value)
    }
  }, [props.value])

  if (props.multiSelect) {
    useEffect(() => {
      setInputState(null);
    });
  }

  const onChange = value => {
    props.onSelect(value);
    setInputState(value);
  };

  const availableOptions = props.options.filter(options => {
    return !options?.isSelected ? options : false;
  });

  return (
    <InputDropdownSearchWrapper
      isError={props.isError}
      isSimpleSelect={props.simpleSelect}
    >
      <SelectSearch
        onChange={onChange}
        value={inputState}
        options={availableOptions}
        search={props.simpleSelect ? false : true}
        placeholder={
          props.placeholder ? props.placeholder : 'Type your tool here'
        }
      />
    </InputDropdownSearchWrapper>
  );
};

InputDropdownSearch.propTypes = {};

InputDropdownSearch.defaultProps = {};

export default InputDropdownSearch;
