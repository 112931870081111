import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GET_CREATE_DRAFTS } from '@src/apollo/query';
import { useQuery } from '@apollo/client';

import { navigate } from 'gatsby';

import String from '@components/atoms/string/String';
import Table from '@components/organisms/table/Table';
import { formatDate } from '@src/utils/formatDate';
import { Text } from '@components/atoms/text/Text';
import Options from '@components/organisms/table/components/columnItem/Options';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';

export const CreateDraftList = props => {
  const [selectUserContent, setSelectUserContent] = useState({
    isLoaded: false,
    limitRow: 12,
    page: 1,
    currentCount: '1-12',
    maxPage: 1,
    totalCount: 0,
    isSelectUserContent: true,
    columns: [
      {
        header: <String id="select_user_content_content" />,
        accessor: 'title',
      },
      {
        header: <String id="community_admin_content_type" />,
        accessor: 'type',
      },
      {
        header: <String id="select_user_content_creation_date" />,
        accessor: 'publish_date',
      },
      {
        header: '',
        accessor: 'actions',
      },
    ],
    rowData: [],
  });

  const [userContent, setUserContent] = useState({
    userContentList: [],
  });

  const { loading, error, data, refetch } = useQuery(GET_CREATE_DRAFTS, {
    variables: {
      status: 'draft',
      limit: selectUserContent.limitRow,
      page: selectUserContent.page,
    },
    fetchPolicy: 'cache-and-network',
  });

  const onNextPage = () => {
    if (selectUserContent.page < selectUserContent.maxPage) {
      const pageCountFirstNumber =
        selectUserContent.page * selectUserContent.limitRow + 1;
      let pageCountSecondNumber =
        pageCountFirstNumber + selectUserContent.limitRow - 1;

      if (pageCountSecondNumber > selectUserContent.totalCount) {
        pageCountSecondNumber = selectUserContent.totalCount;
      }

      setSelectUserContent({
        ...selectUserContent,
        page: selectUserContent.page + 1,
        currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
        loading: true,
        isLoaded: false,
      });
    }
  };

  const onPrevPage = () => {
    const pageCountFirstNumber =
      (selectUserContent.page - 2) * selectUserContent.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + selectUserContent.limitRow - 1;
    if (pageCountSecondNumber > selectUserContent.totalCount) {
      pageCountSecondNumber = selectUserContent.totalCount;
    }
    setSelectUserContent({
      ...selectUserContent,
      page: selectUserContent.page - 1,
      currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
      isLoaded: false,
    });
  };

  const onChangeLimitRow = rowCount => {
    setSelectUserContent({
      ...selectUserContent,
      limitRow: rowCount,
      page: 1,
      maxPage: Math.ceil(selectUserContent.totalCount / rowCount),
      isLoaded: false,
      currentCount: `1 - ${
        selectUserContent.limitRow < data.userContents.total
          ? selectUserContent.limitRow
          : data.userContents.total
      }`,
    });
    window.scrollTo(0, 0);
  };

  const singleRowActions = [
    {
      label: 'Edit',
      onClickAction: item => {
        navigate(`${item.editUrl}/edit`);
      },
    },
    {
      label: 'Preview',
      onClickAction: item => {
        navigate(item.editUrl);
      },
    },
    {
      label: 'Delete',
      onClickAction: item => {
        props.onDeleteDraft(item.id, refetch);
      },
    },
  ];

  useEffect(() => {
    if (data) {
      const pageCountFirstNumber =
        (selectUserContent.page - 1) * selectUserContent.limitRow + 1;
      let pageCountSecondNumber =
        pageCountFirstNumber + selectUserContent.limitRow - 1;
      if (pageCountSecondNumber > data.userContents.total) {
        pageCountSecondNumber = data.userContents.total;
      }

      setSelectUserContent({
        ...selectUserContent,
        isLoaded: true,
        totalCount: data.userContents.total,
        currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
        maxPage: Math.ceil(
          data.userContents.total / selectUserContent.limitRow
        ),
      });

      const rowDatas = [];
      data.userContents.data.forEach((element, index) => {
        const elementContentType = () => {
          if (
            element.userContentType === 'article' &&
            element.article_type?.id > 1
          ) {
            if (element.article_type.id === 2) {
              return 'motm';
            } else if (element.article_type.id === 3) {
              return 'moty';
            }
          } else {
            return element.userContentType;
          }
        };

        const singleRow = {
          title: <Text>{element.title}</Text>,
          index,
          type: <Text>{elementContentType()}</Text>,
          publish_date: formatDate(element.publishedAt || element.createdAt),
          actions: (
            <Options
              alignLeft
              actions={singleRowActions}
              id={element.id}
              editUrl={`/m/${element.creator.username}/${
                element.userContentType
              }${element.userContentType !== 'tutorial_series' ? 's' : ''}/${
                element.slug
              }/draft`}
            />
          ),
        };
        rowDatas.push(singleRow);
      });

      setUserContent({
        userContentList: rowDatas,
      });
    }
  }, [data]);

  useEffect(() => {
    const pageCountFirstNumber =
      (selectUserContent.page - 1) * selectUserContent.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + selectUserContent.limitRow - 1;
    if (pageCountSecondNumber > selectUserContent.totalCount) {
      pageCountSecondNumber = selectUserContent.totalCount;
    }
    setSelectUserContent({
      ...selectUserContent,
      currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
    });
  }, [selectUserContent.limitRow]);

  if (loading && !data) {
    return <LoaderSkeleton height="200px" width="100%" isRadius />;
  } else {
    return (
      <Table
        nextPage={onNextPage}
        prevPage={onPrevPage}
        changeRowPerPage={onChangeLimitRow}
        totalCount={selectUserContent.totalCount}
        currentCount={selectUserContent.currentCount}
        loading={loading}
        rowData={userContent.userContentList}
        columns={selectUserContent.columns}
        maxPage={selectUserContent.maxPage}
        isSelectUserContent
        isNotMultiSelect
        currentPage={selectUserContent.page}
      />
    );
  }
};

CreateDraftList.propTypes = {
  drafts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.string,
      postDate: PropTypes.string,
    })
  ).isRequired,
  onDeleteDraft: PropTypes.func.isRequired,
};
export default CreateDraftList;
