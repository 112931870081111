import { styled } from '@root/stitches.config';

export const AdvanceFiltersWrapper = styled('div', {
  marginBottom: '$16'
});

export const AdvanceFiltersHeader = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  ai: 'flex-start',
  flexDirection: 'column',
  width: '100%',
  '@bp0': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 'auto',
  },

  '@bp1': {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  '@bp2': {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export const AdvanceFiltersHeaderLeft = styled('div', {
  marginBottom: '$16',
  display: 'flex',
  width: '30%',
  ai: 'center',
  '@bp2': {
    marginBottom: '0',
  },
});

export const CheckboxOptions = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
});

export const RadioOptions = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
});

export const CheckboxFilterWrapper = styled('div', {
  marginBottom: '$24',
  width: '100%',
});

export const DateFilterWrapper = styled('div', {
  marginBottom: '$24',
  // marginTop: '$24',
  width: '340px',
});

export const AdvanceFiltersActions = styled('div', {
  width: '100%',
  marginTop: '$16',
});

export const RadioFilterWrapper = styled('div', {
  marginBottom: '$24',
  width: '100%',
  label: {
    fontSize: '$12',
  },
});

export const AdvanceFiltersHeaderRight = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  
  button: {
    whiteSpace: 'nowrap',
  },
  input: {
    height: '100%',
  },
  '@bp2': {
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  width: '100%',
  '@bp0': {
    width: 'auto',
  },
});

export const AdvanceFiltersAllFilters = styled('div', {
  width: '100%',
  br: '$8',
  backgroundColor: '$bg',
  padding: '$24 $32',
  marginTop: '$16',
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
});

export const DatePickerWrapper = styled('div', {
  // marginBottom: '$24',
  display: 'flex',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: '$16',
  alignItems: 'end',
  // alignItems: 'center',
});
