import {
  BackgroundImageWrapper,
  ContentContainer,
  GroupActions,
  GroupBoxWrapper,
  GroupTitle,
  IconImage,
  IconWrapper,
  ImageWrapper,
  JoinLabel,
  JoinedLabel,
  ListingDescription,
  MemberSinceWrapper,
  StyledLink,
} from './GroupBox.styled';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '@context/AuthContext';
import Image from '@components/atoms/image/Image';
import Modal from '@components/atoms/modal/Modal';
import PropTypes from 'prop-types';
import PublishNotification from '@components/molecules/publishNotification/PublishNotification';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import useModal from '@components/atoms/modal/useModal';
import { isGroupMember } from '@src/utils/getMemberGroupInfo';
import CommunityFeedContext from '@context/CommunityFeedContext';
import LoaderSpinner from '@components/atoms/loaderSpinner/LoaderSpinner';
import { useMutation, useQuery } from '@apollo/client';
import { JOIN_GROUPS } from '@src/apollo/mutation';
import { GET_MY_GROUPS } from '@src/apollo/query';

const GroupBox = props => {
  const [isAuthState, setIsAuthState] = useState(false);
  const [communityState, setCommunityState] = useContext(CommunityFeedContext);
  const authContext = useContext(AuthContext);

  const [joinGroups, { loading, error, data }] = useMutation(JOIN_GROUPS, {
    refetchQueries: [{ query: GET_MY_GROUPS }],
  });

  const onJoinGroup = id => {
    const newArray = communityState.myGroupsId;
    newArray.push(id);
    joinGroups({ variables: { groups: newArray } });
  };

  useEffect(() => {
    if (data) {
      let groupsIds = [];
      data.updateUserProfile.groups.map((group, i) => {
        groupsIds.push(group.id);
      });

      setCommunityState({
        myGroupsId: groupsIds,
      });
    }
  }, [data]);

  useEffect(() => {
    setIsAuthState(authContext.isAuthenticated);
  }, [authContext]);

  const onClickJoinGroup = e => {
    e.preventDefault();
    isAuthState
      ? authContext.isVerified && authContext.isOnboarded
        ? onJoinGroup(props.groupId)
        : toggle()
      : toggle();
  };

  const { isShowing, toggle } = useModal();

  return (
    <GroupBoxWrapper inListing={props.inListing} inSlider={props.inSlider}>
      {props.imageGroupHead && (
        <StyledLink
          alt={props.groupTitle}
          to={props.imageGroupHead.url}
          inListing={props.inListing}
          inSlider={props.inSlider}
        >
          <BackgroundImageWrapper>
            <ImageWrapper>
              <Image
                src={props.imageGroupHead}
                alt={props.groupTitle}
                className="groupbox-header-image"
              />
            </ImageWrapper>

            <IconImage
              inListing={props.inListing}
              isNew={props.isNew}
              inSlider={props.inSlider}
            >
              <IconWrapper>
                <Image src={props.imageGroupIcon} alt={props.groupTitle} />
              </IconWrapper>
            </IconImage>
          </BackgroundImageWrapper>

          <ContentContainer
            inListing={props.inListing}
            inSlider={props.inSlider}
          >
            <div>
              <GroupTitle inListing={props.inListing} inSlider={props.inSlider}>
                {props.groupTitle}
              </GroupTitle>
              {props.inListing ? (
                <ListingDescription
                  size="14"
                  dangerouslySetInnerHTML={{ __html: props.description }}
                ></ListingDescription>
              ) : null}
            </div>
            <GroupActions>
              {!isGroupMember(communityState.myGroupsId, props.groupId) ? (
                <JoinLabel
                  inListing={props.inListing}
                  inSlider={props.inSlider}
                  onClick={onClickJoinGroup}
                >
                  <Svg icon={'plus'} css={{ fill: '$blackToWhite' }} />
                  <String id="groups_join" />
                </JoinLabel>
              ) : (
                <MemberSinceWrapper inListing={props.inListing}>
                  <span>
                    {loading ? (
                      <LoaderSpinner size={24} />
                    ) : (
                      <>
                        <Svg
                          height={16}
                          width={16}
                          icon={'check'}
                          css={{ fill: '$green' }}
                        />

                        <span>Member</span>
                      </>
                    )}
                  </span>
                </MemberSinceWrapper>
              )}
            </GroupActions>
          </ContentContainer>
        </StyledLink>
      )}
      <Modal isShowing={isShowing} hide={toggle} hasImageHeader>
        {(!authContext.isOnboarded || !authContext.isVerified) &&
        authContext.isAuthenticated ? (
          <PublishNotification
            type={!authContext.isOnboarded ? 'onboarding' : 'validation'}
            url={!authContext.isOnboarded ? `/m/${authContext.userSlug}` : ''}
          />
        ) : (
          <PublishNotification type="join" />
        )}
      </Modal>
    </GroupBoxWrapper>
  );
};

GroupBox.propTypes = {
  imageGroupHead: PropTypes.object,
  imageGroupIcon: PropTypes.object,
  groupTitle: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  isMember: PropTypes.bool,
};

export default GroupBox;
