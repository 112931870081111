import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';
import { Heading } from '@components/atoms/heading/Heading';
import Svg from '@components/atoms/svg/Svg';

export const AppBannerWrapper = styled('div', {
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  height: `auto`,
  width: `100%`,
  padding: `$48 0`,
  backgroundColor: `$darkGrey`,
  backgroundImage: `url("/images/app_banner_bg.jpg")`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,

  '.btnContainer > a:first-child': {
    margin: `0 0 $16 0`,
  },

  '@bp2': {
    padding: `120px 0`,

    '.btnContainer > a:first-child': {
      margin: `0 $16 0 0`,
    },
  },
});

export const AppBannerBtnWrapper = styled('div', {
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  gridGap: '$16',
  marginTop: `$40`,
  marginTop: `$32`,
  alignItems: 'center',
  justifyContent: 'center',

  a: {
    width: '250px',
    '@bp2': {
      width: 'auto',
    },
  },

  '@bp2': {
    display: 'grid',
    gridTemplateColumns: '280px 280px',
    gridGap: '$16',
  },
});

export const ButtonStyled = {
  width: `auto`,
  height: `auto`,
  padding: `$16`,
  borderRadius: '$8',
};

export const TextStyled = styled(Text, {
  marginLeft: '$16',
});

export const HeadingStyled = styled(Heading, {
  color: '$white',

  '&.main-heading': {
    fontSize: '45px',
    lineHeight: '1em',
    my: '10px',

    '@bp2': { fontSize: '60px' },
  },
});

export const SvgStyled = styled(Svg, {
  margin: '0 $16 0 0',
});

export const AppBannerContentWrapper = styled('div', {
  padding: '0 $16',
  textAlign: 'center',
});

export const StyledAppsLinks = styled('a', {
  color: '$black',
  display: 'grid',
  gridTemplateColumns: '$24 1fr',
  gridGap: '$8',
  padding: '$12 $24',
  backgroundColor: '$white',
  borderRadius: '$12',
  textDecoration: 'none',

  fontSize: '$14',
  ai: 'center',
  // maxWidth: '250px',
  transition: 'all .5s ease',

  svg: {
    fill: '$black',
    height: '$24',
    width: '$24',
  },
  '&:hover': {
    backgroundColor: '$darkGrey',
    transition: 'all .5s ease',
  },
});
