import {
  ImageWrapper,
  InputTextErrorMsg,
  InputTextLabel,
  RemoveButton,
  RequiredLabel,
  SelectToolsHeader,
  SelectToolsWrapper,
  SelectedToolsTitle,
  SelectedToolsWrapper,
  Tool,
} from './SelectTools.styled';
import InputDropdownSearch from '@components/atoms/input/inputDropdownSearch/InputDropdownSearch';
import PropTypes from 'prop-types';
import React from 'react';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import { sortArrayByName } from '@src/utils/sortArray';

const SelectTools = props => {
  const {
    maxTools,
    isError,
    error,
    toolsList,
    css,
    label,
    required,
    onToolsSelect,
    labelInfo,
  } = props;

  const selectedTools = toolsList.filter(tool => {
    return tool.isSelected ? tool : false;
  });

  const onSelectTool = (value, isRemove = false) => {
    const selectedIndex = toolsList.findIndex(tool => {
      return tool.value === value;
    });

    if (selectedTools.length >= maxTools && !isRemove) {
      return;
    }

    onToolsSelect(selectedIndex);
  };

  const maxSelected = selectedTools.length === maxTools;

  return (
    <SelectToolsWrapper css={css}>
      <SelectToolsHeader>
        {label && (
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <InputTextLabel isError={isError}>{label}</InputTextLabel>
            <Text size="14">
              {maxSelected ? '(Max amount reached)' : `(add up to ${maxTools})`}
            </Text>
          </div>
        )}
        {required ? (
          <RequiredLabel isError={isError} size="12">
            Required
          </RequiredLabel>
        ) : null}
      </SelectToolsHeader>
      <InputDropdownSearch
        onSelect={onSelectTool}
        options={toolsList.slice().sort(sortArrayByName)}
        multiSelect="true"
        isError={isError}
      />
      {selectedTools.length > 0 ? (
        <SelectedToolsWrapper>
          <SelectedToolsTitle>Selected tools:</SelectedToolsTitle>
          {selectedTools.map((item, i) => {
            let imageUrl = '';
            const url = item.imageFileName?.path;
            const trimSlashes = str =>
              str
                .split('/')
                .filter(v => v !== '')
                .join('/');
            const newUrl = trimSlashes(url);

            if (newUrl.includes('images')) {
              imageUrl = `/${newUrl}`;
            } else {
              imageUrl = `${CDN_URL}${newUrl}`;
            }

            return (
              <Tool key={i}>
                <RemoveButton
                  onClick={onSelectTool.bind(null, item.value, true)}
                >
                  <Svg icon="close-small" />
                </RemoveButton>
                <ImageWrapper>
                  <img src={imageUrl} />
                </ImageWrapper>
              </Tool>
            );
          })}
        </SelectedToolsWrapper>
      ) : null}
      {isError ? (
        <InputTextErrorMsg isError={isError}>{error}</InputTextErrorMsg>
      ) : null}
    </SelectToolsWrapper>
  );
};

SelectTools.propTypes = {
  maxTools: PropTypes.number,
  isError: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toolsList: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired,
  css: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  onToolsSelect: PropTypes.func,
};

SelectTools.defaultProps = {
  maxTools: 12,
  isError: false,
  error: '',
  css: {},
  label: undefined,
  labelInfo: undefined,
  required: false,
  onToolsSelect: () => {},
};

export default SelectTools;
