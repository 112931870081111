import {
  CenterBlock,
  CircleNumber,
  CreateNavigationContainer,
  CreateNavigationWrapper,
  LeftBlock,
  Separator,
  Step,
} from './CreateNavigation.styled';

import Breadcrumb from '@components/molecules/breadcrumb/Breadcrumb';
import Button from '@components/atoms/button/Button';
import PropTypes from 'prop-types';
import React from 'react';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import { warningLeaveMessage } from '../utils';
import { useIsMobile } from '@src/utils/useIsMobile';

const CreateNavigation = props => {
  const {
    currentStep,
    isNavActive,
    isEdit,
    isDraft,
    isNextBtnDisabled,
    onNavItemClick,
    gotoPrevSection,
    setModalData,
    createType,
    title,
    pathname,
  } = props;

  const isMobile = useIsMobile();

  function breadCrumbPathname(){
    if(!isEdit && !isDraft){
      return `/create/${createType}`
    } else if(isEdit){
      return `${title === "" ? "Untitled" : title}`
    } else if(isDraft){
      return "create/draft/edit"
    }
  }

  function onBreadcrumbItemClick(fullPath, path){
    const navPath = path === title ? pathname.replace('/edit', '') : fullPath;
    warningLeaveMessage(
      setModalData,
      createType,
      navPath
    );
  }

  return (
    <>
      <CreateNavigationWrapper>
        <CreateNavigationContainer>
          <LeftBlock>
          {!isMobile && (
            <Breadcrumb 
              pathname={breadCrumbPathname()}
              isOnClickItem
              onClick={onBreadcrumbItemClick}
              isEdit={isEdit}
            />
          )}
          </LeftBlock>
          {isNavActive && (
            <CenterBlock>
              {currentStep > 0 && (
                <Button
                  type="circle"
                  svgIcon="arrow-left-short"
                  colorTheme="lightgray"
                  actionType="onclick"
                  onClick={gotoPrevSection}
                  css={{
                    position: 'absolute',
                    left: '$16',
                    minWidth: '44px',
                    minHeight: '44px',
                    '@bp2': {
                      display: 'none',
                    },
                  }}
                />
              )}

              <Step
                onClick={onNavItemClick.bind(null, 0)}
                isActive={currentStep >= 0 ? 'isActive' : 'isNotActive'}
              >
                <CircleNumber className="circle">
                  <span>1</span>
                </CircleNumber>
                <Text className="text" size="12">
                  <String id="create_navigation_steps_information" />
                </Text>
              </Step>
              <Separator />
              <Step
                onClick={onNavItemClick.bind(null, 1)}
                isActive={currentStep >= 1 ? 'isActive' : 'isNotActive'}
              >
                <CircleNumber className="circle">
                  <span>2</span>
                </CircleNumber>
                <Text className="text" size="12">
                  <String id="create_navigation_steps_build" />
                </Text>
              </Step>
              <Separator />
              <Step
                onClick={!isNextBtnDisabled ? onNavItemClick.bind(null, 2) : null}
                isActive={currentStep >= 2 ? 'isActive' : 'isNotActive'}
                isDisabled={isNextBtnDisabled}
              >
                <CircleNumber className="circle">
                  <span>3</span>
                </CircleNumber>
                <Text className="text" size="12">
                  <String id="create_navigation_steps_cover" />
                </Text>
              </Step>
            </CenterBlock>
          )}
        </CreateNavigationContainer>
      </CreateNavigationWrapper>
      {isMobile && (
        <Breadcrumb 
          pathname={breadCrumbPathname()}
          isOnClickItem
          onClick={onBreadcrumbItemClick}
          isEdit={isEdit}
          css={{
            margin: '$16 0'
          }}
        />
      )}
    </>
  );
};

CreateNavigation.propTypes = {
  currentStep: PropTypes.number.isRequired,
  isNavActive: PropTypes.bool,
  isEdit: PropTypes.bool,
  isNextBtnDisabled: PropTypes.bool,
  onNavItemClick: PropTypes.func,
  gotoPrevSection: PropTypes.func,
  onBreadcrumbItemClick: PropTypes.func,
};

CreateNavigation.defaultProps = {
  isNavActive: false,
  isEdit: false,
  isNextBtnDisabled: false,
  onNavItemClick: () => {},
  gotoPrevSection: () => {},
  onBreadcrumbItemClick: () => {},
};

export default CreateNavigation;
