import { styled } from '@root/stitches.config';

export const ExploreAreaBannerWrapper = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 0 84px 0',
  minHeight: '614px',
  justifyContent: 'center',
  '@bp2': {
    justifyContent: 'center',
    minHeight: '880px',
  },
  '.explore_area_banner_text': {
    textAlign: 'center',
    fontSize: '$14',
    '@bp2': {
      fontSize: '$20',
      maxWidth: '416px',
    },
  },
  '.explore_area_banner_artworks': {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    '@bp2': {
      alignSelf: 'center',
      width: '1192px',
    },
    '> .image-wrapper:first-of-type': {
      '@bp2': {
        zIndex: 1,
        width: '250px',
        height: '316px',
        top: '420px',
        left: 0,
      },
    },
    '> .image-wrapper:nth-of-type(2)': {
      width: '130px',
      height: '98px',
      left: '28px',
      top: 0,
      '@bp2': {
        width: '380px',
        height: '300px',
        top: 0,
        left: '250px',
      },
    },
    '> .image-wrapper:nth-of-type(3)': {
      left: '-$72',
      width: '360px',
      height: '220px',
      bottom: 0,
      '@bp2': {
        left: '400px',
        width: '360px',
        height: '220px',
        bottom: 0,
      },
    },
    '> .image-wrapper:nth-of-type(4)': {
      width: '196px',
      height: '152px',
      top: '$28',
      right: '-$32',
      '@bp2': {
        top: '85px',
        right: '204px',
        width: '192px',
        height: '150px',
      },
    },
    '> .image-wrapper:last-of-type': {
      '@bp2': {
        top: '375px',
        right: 0,
        width: '328px',
        height: '410px',
      },
    },
    '> .image-wrapper': {
      position: 'absolute !important',
      br: 16,
      boxShadow: '$2',
      overflow: 'hidden',
      img: {
        position: 'absolute'
      }
    },
  },
  '.explore_area_banner_content_wrapper': {
    alignSelf: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '$16',
    lineHeight: '$3',
    '@bp2': {
      marginTop: '$24',
    },
  },
  '.explore_area_banner_text_marquee': {
    marginTop: '150px',
    '@bp2': {
      marginTop: '$48',
    },
  },
  'a, button': {
    marginTop: '$16',
  },
});
