import { ChildrenWrapper, Wrapper } from './TextMarquee.styled';

import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@components/atoms/text/Text';

const TextMarquee = props => {
  const { text, textIterations, variant, isAnimated } = props;

  return (
    <Wrapper
    // isAnimated={isAnimated}
    // {...props}
    >
      <ChildrenWrapper isAnimated={isAnimated} variant={variant}>
        {[...Array(textIterations)].map((item, index) => {
          return (
            <div className="child" key={`marqueeChild_${text + index}`}>
              {(variant === 'small' || variant === 'medium') && (
                <Text as="p">{text}</Text>
              )}
              {(variant === 'large' || variant === 'hero') && (
                <Text as="h1">{text}</Text>
              )}
            </div>
          );
        })}
      </ChildrenWrapper>
    </Wrapper>
  );
};

function withinFiveToTwenty(props, propName, componentName) {
  const compName = componentName || 'ANONYMOUS';

  if (props[propName]) {
    const value = props[propName];
    if (typeof value === 'number') {
      return value >= 5 && value <= 20
        ? null
        : new Error(`${propName} in ${compName} is not within 5 to 20`);
    }
  }
  return null;
}

TextMarquee.propTypes = {
  text: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['small', 'medium', 'large', 'hero']),
  textIterations: withinFiveToTwenty,
  isAnimated: PropTypes.bool,
};

TextMarquee.defaultProps = {
  // note: the smaller the text is the more you'll need to add iterations
  textIterations: 5,
  variant: 'small',
  isAnimated: false,
};

export default TextMarquee;
