import {
  ArticlesWrapper,
  HighlightWrapper,
  StyledButton,
  StyledThumbnailSlider,
} from './Inspire.styled';
import {
  LandingPageWrapper,
  SectionWrapper,
} from '@components/organisms/layout/Layouts.styled';
import {
  GET_INSPIRE_CONTENT,
  GET_MEMBERS_OF,
  GET_USERCONTENTS,
} from '@src/apollo/query';
import { GET_PAGE_DATA_INSPIRE } from '@src/apollo/queryPages';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { getImageSrc } from '@src/utils/getImageSrc';
import AuthContext from '@src/context/AuthContext';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import Layout from '@components/organisms/layout/Layout';
import ListingHeader from '@components/molecules/listingHeader/ListingHeader';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import PageHero from '@components/organisms/pageHero/PageHero';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';
import UrlsContext from '@context/UrlsContext';
import { dataToThumbnailViewModel } from '@src/utils/dataToThumbnailViewModel';
import { useIsMobile } from '@src/utils/useIsMobile';
import { validURL } from '@src/utils/validURL';

const Inspire = context => {
  const UrlContext = useContext(UrlsContext);
  const authContext = useContext(AuthContext);
  const isMobile = useIsMobile();
  const [heroData, setHeroData] = useState();
  const [excludedIds, setExcludedIds] = useState();

  const {
    data: pageData,
    loading: pageDataLoading,
    error: pageDataError,
  } = useQuery(GET_PAGE_DATA_INSPIRE, {
    variables: {
      slug: 'inspire',
    },
    context: { clientName: 'public' },
  });

  const [getMixedContent, mixedContent] = useLazyQuery(GET_INSPIRE_CONTENT, {
    variables: {
      limit: 8,
      exclude_ids: excludedIds,
    },
    context: { clientName: 'public' },
  });

  const [getGalleryBlock, galleryBlock] = useLazyQuery(GET_USERCONTENTS, {
    variables: {
      limit: 8,
      page: 1,
      content_type: 'artwork',
      filters: {
        exclude_ids: excludedIds,
      },
    },
    context: { clientName: 'public' },
  });

  const [getArticlesBlock, articlesBlock] = useLazyQuery(GET_USERCONTENTS, {
    variables: {
      limit: 4,
      page: 1,
      content_type: 'article',
      filters: {
        article_section: 1,
        article_type: 1,
        exclude_ids: excludedIds,
      },
    },
    context: { clientName: 'public' },
  });

  const [getMembersBlock, membersBlock] = useLazyQuery(GET_MEMBERS_OF, {
    variables: {
      limit: 8,
      page: 1,
      excludedIds,
      article_type: [2],
    },
    context: { clientName: 'public' },
  });

  useEffect(() => {
    if (pageData?.page) {
      const heroBg = getImageSrc(
        pageData.page.background_image,
        'page_hero',
        pageData?.page?.header_image_credit
      );

      setExcludedIds(
        [
          pageData?.page?.userContent_feature?.id,
          pageData?.page?.userContent?.id,
        ].filter(el => el !== undefined)
      );

      setHeroData({
        heading: {
          text: pageData.page.title,
          level: 'medium',
        },
        subHeading: { text: pageData.page.introduction },
        artwork: {
          artistCredits: {
            name: pageData?.page?.header_image_credit,
            url: pageData?.page?.header_image_credit_link,
          },
          image: {
            ...heroBg,
            alt: `Artwork by ${
              pageData?.page?.header_image_credit
                ? pageData?.page?.header_image_credit
                : 'Autodesk'
            }`,
          },
        },
      });
    }
  }, [pageData]);

  useEffect(() => {
    if (excludedIds) {
      getMixedContent();
      getGalleryBlock();
      getArticlesBlock();
      getMembersBlock();
    }
  }, [excludedIds]);

  return (
    <Layout location={context.location}>
      <SEO
        title="Area - Inspire"
        slug="inspire"
        seomatic={context?.pageContext?.entry?.seomatic}
      />
      {heroData ? (
        <PageHero heroData={heroData} />
      ) : (
        <LoaderSkeleton height="560px" width="100%" />
      )}
      <LandingPageWrapper>
        <SectionWrapper>
          <HighlightWrapper>
            {pageData?.page?.userContent_feature && !pageDataLoading && (
              <Thumbnail
                {...dataToThumbnailViewModel(
                  pageData?.page?.userContent_feature,
                  false,
                  authContext.isAuthenticated
                )}
                className="thumbnail-featured"
                isFeatured={!isMobile}
                displayType
              />
            )}
            {pageDataLoading && (
              <LoaderSkeleton
                height="795px"
                width="100%"
                isFeatured={!isMobile}
                isFeaturedReverse={!isMobile}
                isRadius
              />
            )}
            {mixedContent.data?.inspirePageContent?.length >= 1 ? (
              mixedContent.data?.inspirePageContent?.map((item, i) => {
                return (
                  <Thumbnail
                    key={item.id}
                    displayType
                    {...dataToThumbnailViewModel(
                      item,
                      false,
                      authContext.isAuthenticated
                    )}
                  />
                );
              })
            ) : (
              <>
                <LoaderSkeleton height="380px" width="100%" isRadius />
                <LoaderSkeleton height="380px" width="100%" isRadius />
                <LoaderSkeleton height="380px" width="100%" isRadius />
                <LoaderSkeleton height="380px" width="100%" isRadius />
                <LoaderSkeleton height="380px" width="100%" isRadius />
                <LoaderSkeleton height="380px" width="100%" isRadius />
                <LoaderSkeleton height="380px" width="100%" isRadius />
                <LoaderSkeleton height="380px" width="100%" isRadius />
              </>
            )}
          </HighlightWrapper>
        </SectionWrapper>
        <SectionWrapper>
          {galleryBlock.data?.userContents?.data.length >= 1 ? (
            <StyledThumbnailSlider
              title={<String id="inspire_gallery_section_title" />}
              isInternalLink
              btnUrl={GetUrl('inspireGallery', UrlContext)}
              thumbnailList={galleryBlock?.data?.userContents.data.map(el =>
                dataToThumbnailViewModel(el, false, authContext.isAuthenticated)
              )}
              type="artwork"
            />
          ) : (
            <LoaderSkeleton height="200px" width="100%" />
          )}
        </SectionWrapper>
        <SectionWrapper>
          {articlesBlock.data?.userContents.data.length >= 1 ? (
            !isMobile ? (
              <ArticlesWrapper>
                <div className="articlesWrapper_header">
                  <ListingHeader
                    title="page_inspire_articles_section_title"
                    isCMSDynamic
                  />
                  <StyledButton
                    label={<String id="general_see_all" />}
                    actionType="internalLink"
                    internalUrl={GetUrl('inspireArticles', UrlContext)}
                    type="normal"
                    colorTheme="normal"
                  />
                </div>
                <HighlightWrapper>
                  {pageData?.page?.userContent && !pageDataLoading && (
                    <Thumbnail
                      {...dataToThumbnailViewModel(
                        pageData?.page?.userContent,
                        false,
                        authContext.isAuthenticated
                      )}
                      isFeatured={!isMobile}
                    />
                  )}
                  {pageDataLoading && (
                    <LoaderSkeleton
                      height="795px"
                      width="100%"
                      isFeatured={!isMobile}
                      isRadius
                    />
                  )}
                  {articlesBlock.data?.userContents?.data.length >= 1 ? (
                    articlesBlock.data?.userContents?.data.map((item, i) => {
                      return (
                        <Thumbnail
                          key={item.id}
                          {...dataToThumbnailViewModel(
                            item,
                            false,
                            authContext.isAuthenticated
                          )}
                        />
                      );
                    })
                  ) : (
                    <>
                      <LoaderSkeleton height="380px" width="100%" isRadius />
                      <LoaderSkeleton height="380px" width="100%" isRadius />
                      <LoaderSkeleton height="380px" width="100%" isRadius />
                      <LoaderSkeleton height="380px" width="100%" isRadius />
                    </>
                  )}
                </HighlightWrapper>
              </ArticlesWrapper>
            ) : (
              <StyledThumbnailSlider
                title={<String id="inspire_articles_section_title" />}
                isInternalLink
                thumbnailList={articlesBlock?.data?.userContents.data.map(el =>
                  dataToThumbnailViewModel(
                    el,
                    false,
                    authContext.isAuthenticated
                  )
                )}
                type="articles"
                btnUrl={GetUrl('inspireArticles', UrlContext)}
              />
            )
          ) : (
            <LoaderSkeleton height="200px" width="100%" />
          )}
        </SectionWrapper>
        <SectionWrapper>
          {membersBlock.data?.userContents.data.length >= 1 ? (
            <StyledThumbnailSlider
              title={<String id="inspire_mom_section_title" />}
              btnUrl="/inspire/member-of-month"
              isInternalLink
              type="ambassadors"
              thumbnailList={membersBlock.data?.userContents.data.map(el =>
                dataToThumbnailViewModel(el, false, authContext.isAuthenticated)
              )}
            />
          ) : (
            <LoaderSkeleton height="200px" width="100%" />
          )}
        </SectionWrapper>
      </LandingPageWrapper>
    </Layout>
  );
};

export default Inspire;
