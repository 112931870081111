import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';

import Button from '@components/atoms/button/Button';
import CreateDraftList from '@components/molecules/createDraftList/CreateDraftList';
import { CreateDraftWrapper } from './CreateDraft.styled';
import { DELETE_USERCONTENT } from '@src/apollo/mutation';
import { Heading } from '@components/atoms/heading/Heading';
import Layout from '@components/organisms/layout/Layout';
import ListingHeader from '@components/molecules/listingHeader/ListingHeader';
import Modal from '@components/atoms/modal/Modal';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import useModal from '@components/atoms/modal/useModal';

const CreateDraft = props => {
  const { isShowing, toggle } = useModal();
  const [draftToDelete, setDraftToDelete] = useState({
    id: null,
    cb: () => {},
  });

  const [deleteUserContent, dataDeleteUserContent] = useMutation(
    DELETE_USERCONTENT,
    {
      onCompleted: () => draftToDelete.cb(),
    }
  );

  const onDeleteDraft = () => {
    toggle();
    deleteUserContent({
      variables: {
        ids: [draftToDelete.id],
      },
    });

    setDraftToDelete({
      ...draftToDelete,
      id: null,
    });
  };

  const onDeleteDraftConfirmation = (id, cb) => {
    setDraftToDelete({ id, cb });
    toggle();
  };

  return (
    <Layout location={props.location} showBreadcrumb>
      <SEO
        title="Create draft"
        slug="create-draft"
        seomatic={props?.pageContext?.entry?.seomatic}
      />
      <CreateDraftWrapper>
        <ListingHeader
          title="create_draft_page_title"
          intro="create_draft_page_intro"
          isCMSDynamic
        />
        <CreateDraftList onDeleteDraft={onDeleteDraftConfirmation} />
      </CreateDraftWrapper>
      <Modal isShowing={isShowing} hide={toggle}>
        <Heading level="h3" css={{ marginBottom: '$32' }}>
          <String id="create_draft_delete_modal_title" />
        </Heading>
        <Button
          actionType="onclick"
          colorTheme="normal"
          onClick={onDeleteDraft}
          label={<String id="create_draft_delete_modal_delete_button_label" />}
          type="normal"
          css={{ marginRight: '$32' }}
        />
        <Button
          actionType="onclick"
          colorTheme="gray"
          onClick={toggle}
          label={<String id="create_draft_delete_modal_cancel_button_label" />}
          type="normal"
        />
      </Modal>
    </Layout>
  );
};
export default CreateDraft;
