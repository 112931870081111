import React, { useEffect, useState } from 'react';

import { CMS_GET_PAGE_DATA } from '@src/apollo/query';
import { Heading } from '@components/atoms/heading/Heading';
import Layout from '@components/organisms/layout/Layout';
import Loader from '@components/atoms/loader/Loader';
import SEO from '@components/atoms/seo/Seo';
import Wysiwyg from '@components/atoms/wysiwyg/Wysiwyg';
import { styled } from '@root/stitches.config';
import { useQuery } from '@apollo/client';
import String from '@components/atoms/string/String';

const Legal = context => {
  const [pageState, setPageState] = useState({
    title: '',
    wysiwyg: '',
    isDataSet: false,
  });

  const { data: dataFromCMS } = useQuery(CMS_GET_PAGE_DATA, {
    variables: {
      slug: context.path.substring(1),
    },
    context: { clientName: 'public' },
  });

  useEffect(() => {
    dataFromCMS
      ? setPageState({
          title: dataFromCMS?.page?.title,
          wysiwyg: dataFromCMS?.page?.wysiwyg,
          isDataSet: true,
        })
      : null;
  }, [dataFromCMS]);

  return (
    <Layout location={context.location}>
      <SEO title="Legal" slug="legal" seomatic={context?.pageContext?.entry?.seomatic} />

      <WrapperPage>
        <HeadingStyled level="h1">
          <String id="legal_title_page" />
        </HeadingStyled>
        {pageState.isDataSet ? (
          <Wysiwyg align="left" data={pageState?.wysiwyg} />
        ) : (
          <Loader isLoading={true} />
        )}
      </WrapperPage>
    </Layout>
  );
};
export default Legal;

const WrapperPage = styled('div', {
  minHeight: '30vh',
  maxWidth: '1100px',
  padding: '$48',
  margin: '0 auto',
  justifyContent: 'center',
});

const HeadingStyled = styled(Heading, {
  marginLeft: 'auto',

  '@bp2': {
    marginLeft: '10%',
  },
});
