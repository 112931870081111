import {
  AreaLogo,
  FooterBottom,
  FooterEmptyLeft,
  FooterEmptyRight,
  FooterGrid,
  FooterMenu,
  FooterNav,
  FooterNavWrapper,
  FooterWrapper,
  PolicyNav,
  PolicyNavLink,
  Subscribe,
  SubscribeDescription,
  SvgStyled,
  StyledAppsLinks,
  StyledAppsLinksWrapper,
} from './Footer.styled';
import React, { useContext, useState } from 'react';
import { Link, navigate } from 'gatsby';

import AuthContext from '@context/AuthContext';
import MobileNavBottom from '@components/organisms/layout/mobileNavBottom/MobileNavBottom';
import ProfileMenu from '@components/organisms/layout/footer/profileMenu/ProfileMenu';
import SocialListLink from '@components/molecules/socialListLink/SocialListLink';
import String from '@components/atoms/string/String';
import StringsContext from '@context/StringsContext';
import { Text } from '@components/atoms/text/Text';
import Button from '@components/atoms/button/Button';
import footerMenu from '@src/menus/FooterMenu';

const Footer = ({ className, location }) => {
  const [profileMenuShown, setProfileMenuShown] = useState(false);

  const authContext = useContext(AuthContext);
  const stringsContext = useContext(StringsContext);

  const onProfileClick = () => {
    if (authContext.isAuthenticated) {
      setProfileMenuShown(!profileMenuShown);
    } else {
      navigate(`/login`);
    }
  };

  const socialMediaList = [
    {
      name: 'Instagram',
      icon: 'instagram',
      url: stringsContext?.footer_social_instagram_url?.field,
    },
    {
      name: 'Linkedin',
      icon: 'linkedin',
      url: stringsContext?.footer_social_linkedin_url?.field,
    },
    {
      name: 'Youtube',
      icon: 'youtube',
      url: stringsContext?.footer_social_youtube_3ds_url?.field,
      altTitle: '3ds Max',
    },
    {
      name: 'Youtube',
      icon: 'youtube',
      url: stringsContext?.footer_social_youtube_maya_url?.field,
      altTitle: 'Maya',
    },
    {
      name: 'Youtube',
      icon: 'youtube',
      url: stringsContext?.footer_social_youtube_flame_url?.field,
      altTitle: 'Flame',
    },
  ];

  return (
    <>
      <FooterWrapper>
        <FooterEmptyLeft />
        <FooterGrid>
          <FooterNavWrapper>
            <Link to="/">
              <AreaLogo />
            </Link>
            <FooterMenu>
              <FooterNav
                css={{ display: 'none', '@bp2': { display: 'block' } }}
                display="vertical"
                navItems={footerMenu.footerMain}
                location={location}
              />
              <div>
                <FooterNav
                  css={{ display: 'none', '@bp2': { display: 'block' } }}
                  display="vertical"
                  navItems={footerMenu.footerSecondary}
                  location={location}
                  isExternal
                />
              </div>
              <FooterNav
                className="footer__mobile_nav"
                css={{ '@bp2': { display: 'none' } }}
                display="vertical"
                navItems={footerMenu.policy}
                isExternal
                location={location}
              />
            </FooterMenu>
          </FooterNavWrapper>
          <Subscribe>
            <Text
              as="h4"
              weight="legendBold"
              size="32"
              css={{ color: '#fff', lineHeight: '1.4' }}
            >
              <String id="footer_newsletter_title" />
            </Text>
            <SubscribeDescription>
              <String id="footer_newsletter_description" />
            </SubscribeDescription>
            <Button
              actionType="internalLink"
              internalUrl="/newsletter"
              label={<String id="footer_newsletter_cta_label" />}
              svgIcon="arrow-right-chevron"
              svgLocation="after"
              className="subscribe-button"
            />

            <SocialListLink
              colorTheme="transparentWhite"
              type="circle"
              socialMediaList={socialMediaList}
            />
          </Subscribe>
        </FooterGrid>
        <FooterEmptyRight />
      </FooterWrapper>
      <AuthContext.Consumer>
        {auth => (
          <>
            <FooterBottom className={className}>
              <Text css={{ textAlign: 'center', '@bp3': { ml: '$72' } }}>
                © Copyright 2024 Autodesk Inc. All rights reserved
              </Text>
              <PolicyNav>
                <ul tabIndex="0" className={className}>
                  {footerMenu.policy.map((navItem, i) => {
                    return (
                      <li key={i}>
                        <span>
                          <PolicyNavLink
                            alt={navItem.title}
                            href={navItem.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {navItem.title}
                          </PolicyNavLink>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </PolicyNav>
              {auth.isAuthenticated && (
                <ProfileMenu
                  isShown={profileMenuShown}
                  user="Amy"
                  isLogged={auth.isAuthenticated}
                  auth={auth}
                  onProfileClick={onProfileClick}
                />
              )}
            </FooterBottom>
            <MobileNavBottom
              onProfileClick={onProfileClick}
              isLogged={auth.isAuthenticated}
            />
          </>
        )}
      </AuthContext.Consumer>
    </>
  );
};

Footer.defaultProps = {
  socialMediaList: [
    {
      name: 'Facebook',
      icon: 'facebook',
      url: 'https://www.facebook.com',
    },
    {
      name: 'Twitter',
      icon: 'twitter',
      url: 'https://www.twitter.com',
    },
    {
      name: 'Instagram',
      icon: 'instagram',
      url: 'https://www.instagram.com',
    },
    {
      name: 'LinkedIn',
      icon: 'linkedin',
      url: 'http://www.linkedin.com',
    },
    {
      name: 'Dribbble',
      icon: 'dribbble',
      url: 'http://www.dribbble.com',
    },
  ],
};

export default Footer;
