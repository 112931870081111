import {
  CMS_CHANGE_ROLE,
  CMS_CHANGE_USER_STATUS,
  DELETE_USERS,
} from '@src/apollo/mutation';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import AdvanceFilters from '@components/molecules/advanceFilters/AdvanceFilters';
import Button from '@components/atoms/button/Button';
import DateIconLink from '@components/organisms/table/components/columnItem/DateIconLink';
import { GET_ALL_USERS } from '@src/apollo/query';
import { Heading } from '@components/atoms/heading/Heading';
import Modal from '@components/atoms/modal/Modal';
import Options from '@components/organisms/table/components/columnItem/Options';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import Table from '@components/organisms/table/Table';
import { Text } from '@components/atoms/text/Text';
import TextWithDot from '@components/organisms/table/components/columnItem/TextWithDot';
import UserInfos from '@components/organisms/table/components/columnItem/UserInfos';
import { format } from 'date-fns';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import { navigate } from 'gatsby-link';
import useModal from '@components/atoms/modal/useModal';

const convertUTCToLocalDate = date => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

  return format(date, 'LLL, d y');
};

const Members = context => {
  const { isShowing, toggle } = useModal();

  const [currentSelectedUsers, setCurrentSelectedUsers] = useState({
    usersId: [],
    isDeleted: false,
    isSuspended: false,
  });

  const [usersContent, setUsersContent] = useState({
    isLoaded: false,
    limitRow: 12,
    page: 1,
    currentCount: '1-12',
    maxPage: 1,
    totalCount: 0,
    filters: null,
    columns: [
      {
        header: <String id="community_admin_profile_tab" />,
        accessor: 'profile',
      },
      {
        header: 'Name',
        accessor: 'name',
      },
      {
        header: <String id="community_admin_joined_tab" />,
        accessor: 'joined',
      },
      {
        header: 'Status',
        accessor: 'status',
      },
      {
        header: <String id="community_admin_role_tab" />,
        accessor: 'role',
      },
      {
        header: '',
        accessor: 'options',
      },
      {
        header: '',
        accessor: 'id',
      },
    ],
  });

  const userData = useQuery(GET_ALL_USERS, {
    context: { clientName: 'cms' },
    variables: {
      input: usersContent.filters,
      limit: usersContent.limitRow,
      page: usersContent.page,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [deleteUsers, deleteUsersData] = useMutation(DELETE_USERS, {
    context: { clientName: 'cms' },
    onCompleted: () => {
      userData.refetch();
    },
  });

  const [changeRole, changeRoleData] = useMutation(CMS_CHANGE_ROLE, {
    context: { clientName: 'cms' },
    onCompleted: () => {
      userData.refetch();
    },
  });

  const [changeStatus, changeStatusData] = useMutation(CMS_CHANGE_USER_STATUS, {
    context: { clientName: 'cms' },
    onCompleted: () => {
      userData.refetch();
    },
  });

  const [usersList, setUsersList] = useState([]);
  const onDeleteUser = () => {
    deleteUsers({
      variables: {
        userIds: currentSelectedUsers.usersId,
      },
    });
    toggle();
  };

  const onBringBackUser = () => {
    changeStatus({
      variables: {
        user_ids: currentSelectedUsers.usersId,
      },
    });
    toggle();
  };

  const bulkActions = [
    {
      label: 'Delete Users',
      onClickAction: users => {
        deleteUsers({
          variables: {
            userIds: users.map(u => u.id),
          },
        });
      },
    },
    {
      label: <String id="community_admin_appoint_memberr" />,
      onClickAction: users => {
        changeRole({
          variables: {
            user_ids: users.map(u => u.id),
            role: 'member',
          },
        });
      },
    },
    {
      label: <String id="admin_cms_appoint_contributor" />,
      onClickAction: users => {
        changeRole({
          variables: {
            user_ids: users.map(u => u.id),
            role: 'contributor',
          },
        });
      },
    },
    {
      label: <String id="admin_cms_appoint_admin" />,
      onClickAction: users => {
        changeRole({
          variables: {
            user_ids: users.map(u => u.id),
            role: 'admin',
          },
        });
      },
    },
    {
      label: <String id="admin_cms_appoint_editor" />,
      onClickAction: users => {
        changeRole({
          variables: {
            user_ids: users.map(u => u.id),
            role: 'editor',
          },
        });
      },
    },
  ];

  const getAvatar = (profilePicture, firstname) => {
    return getAvatarImage(profilePicture);
  };

  const onNextPage = () => {
    const pageCountFirstNumber =
      (usersContent.page - 1) * usersContent.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + usersContent.limitRow - 1;
    if (pageCountSecondNumber > userData.data.users.total) {
      pageCountSecondNumber = userData.data.users.total;
    }

    setUsersContent({
      ...usersContent,
      page: usersContent.page + 1,
      currentPageCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onPrevPage = () => {
    const pageCountFirstNumber =
      (usersContent.page - 2) * usersContent.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + usersContent.limitRow - 1;
    if (pageCountSecondNumber > userData.data.users.total) {
      pageCountSecondNumber = userData.data.users.total;
    }

    setUsersContent({
      ...usersContent,
      page: usersContent.page - 1,
      currentPageCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onChangeLimitRow = rowCount => {
    setUsersContent({
      ...usersContent,
      limitRow: rowCount,
      page: 1,
      maxPage: Math.ceil(usersContent.totalCount / rowCount),
    });
  };

  useEffect(() => {
    if (userData.data) {
      const rowDatas = [];
      userData.data.users.data.forEach(user => {
        const singleRowActions = [
          {
            label: 'Edit',
            onClickAction: e => {
              navigate(e.editUrl);
            },
          },
          {
            label:
              user.role !== 'contributor' ? (
                <String id="admin_cms_appoint_contributor" />
              ) : (
                <String id="community_admin_appoint_memberr" />
              ),
            onClickAction: e => {
              changeRole({
                variables: {
                  user_ids: [e.id],
                  role: user.role !== 'contributor' ? 'contributor' : 'member',
                },
              });
            },
          },
          {
            label:
              user.role !== 'admin' ? (
                <String id="admin_cms_appoint_admin" />
              ) : (
                <String id="community_admin_appoint_memberr" />
              ),
            onClickAction: e => {
              changeRole({
                variables: {
                  user_ids: [e.id],
                  role: user.role !== 'admin' ? 'admin' : 'member',
                },
              });
            },
          },
          {
            label:
              user.role !== 'editor' ? (
                <String id="admin_cms_appoint_editor" />
              ) : (
                <String id="community_admin_appoint_memberr" />
              ),
            onClickAction: e => {
              changeRole({
                variables: {
                  user_ids: [e.id],
                  role: user.role !== 'editor' ? 'editor' : 'member',
                },
              });
            },
          },
          {
            label:
              user.status === 'deleted' ? 'Bring back member' : 'Delete Member',
            status: user.status === 'deleted' ? 'helpCarefull' : 'carefull',
            onClickAction: item => {
              setCurrentSelectedUsers({
                usersId: [item.id],
                isDeleting: user.status !== 'deleted',
                isDeleted: user.status === 'deleted',
              });
              toggle();
            },
          },
          user.status === 'suspended' && {
            label: 'Bring back member',
            status: 'helpCarefull',
            isSuspended: true,
            onClickAction: item => {
              setCurrentSelectedUsers({
                usersId: [item.id],
                isSuspended: true,
              });
              toggle();
            },
          },
        ];

        let color = '';
        let role = '';
        if (user.role === 'member') {
          color = 'gray';
          role = <String id="community_admin_filter_member" />;
        } else if (user.role === 'contributor') {
          color = 'green';
          role = <String id="admin_cms_contributor" />;
        } else if (user.role === 'editor') {
          color = 'blue';
          role = <String id="admin_cms_editor" />;
        } else if (user.role === 'admin') {
          color = 'yellow';
          role = <String id="admin_cms_admin" />;
        }

        if (user.status === 'suspended') {
          color = 'red';
          role = user.status;
        }

        if (user.status === 'deleted') {
          color = 'red';
          role = <String id="admin_cms_deleted" />;
        }

        let name = '';

        if (user.first_name !== null && user.first_name !== '') {
          name = `${user.first_name} ${user.last_name}`;
        }
        const singleRow = {
          profile: (
            <UserInfos
              name={user.username}
              job={user.title}
              url={user.userProfile.profile_picture}
              email={user.email}
              link={`/m/${user.username}`}
            />
          ),
          name: <Text>{name}</Text>,
          status: (
            <Text css={{ textTransform: 'capitalize' }}>
              {!user.is_onboarded ? 'Pending' : user.status}
            </Text>
          ),
          role: <TextWithDot status={role} color={color} />,
          joined: (
            <DateIconLink date={convertUTCToLocalDate(user.createdAt * 1000)} />
          ),
          options: (
            <Options
              actions={singleRowActions}
              id={user.id}
              isDisabled={
                user.status === 'deleted' ||
                // user.role === 'admin' ||
                user.status === 'suspended'
              }
              isAdmin={user.role === 'admin'}
              //isDeleted={user.status === 'deleted'}
              editUrl={`/m/${user.username}`}
            />
          ),
          id: user.id,
        };
        rowDatas.push(singleRow);
      });

      const pageCountFirstNumber =
        (usersContent.page - 1) * usersContent.limitRow + 1;
      let pageCountSecondNumber =
        pageCountFirstNumber + usersContent.limitRow - 1;
      if (pageCountSecondNumber > userData.data.users.total) {
        pageCountSecondNumber = userData.data.users.total;
      }

      setUsersContent({
        ...usersContent,
        isLoaded: true,
        totalCount: userData.data.users.total,
        currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
        maxPage: Math.ceil(userData.data.users.total / usersContent.limitRow),
      });

      setUsersList(rowDatas);
    }
  }, [userData.data]);

  const onApplyFilters = () => {
    // All Filters
    const allSelectedFilters = {};
    // radio - Roles
    let role = null;
    let status = null;
    filtersState.filters.forEach(filter => {
      if (filter.name === 'role') {
        role = filter.selectedValue;
      }
      if (filter.name === 'status') {
        status = filter.selectedValue;
      }
    });
    if (role) {
      allSelectedFilters.roles = role;
    }
    if (status) {
      allSelectedFilters.status = status;
    }

    let join_date_start = null;
    let join_date_end = null;
    filtersState.filters.forEach(filter => {
      if (filter.name === 'date-joined') {
        join_date_start = filter.startDate;
        join_date_end = filter.endDate;
      }
    });
    if (join_date_start) {
      allSelectedFilters.join_date_start = join_date_start.toString();
    }
    if (join_date_end) {
      allSelectedFilters.join_date_end = join_date_end.toString();
    }

    let last_post_date_start = null;
    let last_post_date_end = null;
    filtersState.filters.forEach(filter => {
      if (filter.name === 'last-post') {
        last_post_date_start = filter.startDate;
        last_post_date_end = filter.endDate;
      }
    });
    if (last_post_date_start) {
      allSelectedFilters.last_post_date_start = last_post_date_start.toString();
    }
    if (last_post_date_end) {
      allSelectedFilters.last_post_date_end = last_post_date_end.toString();
    }
    setUsersContent({
      ...usersContent,
      filters: allSelectedFilters,
    });

    userData.refetch();
  };

  const onChangeRoleFilter = e => {
    const updatedFilters = { ...filtersState };
    updatedFilters.filters.forEach(filter => {
      if (filter.name === 'role') {
        filter.selectedValue = e.currentTarget.value;
        filter.options.forEach(option => {
          option.isSelected = false;
          if (option.value === e.currentTarget.value) {
            option.isSelected = e.currentTarget.checked;
          }
        });
      }
    });
    setFiltersState({ filters: updatedFilters.filters });
  };

  const onChangeStatus = e => {
    const updatedFilters = { ...filtersState };
    updatedFilters.filters.forEach(filter => {
      if (filter.name === 'status') {
        filter.selectedValue = e.currentTarget.value;
        filter.options.forEach(option => {
          option.isSelected = false;
          if (option.value === e.currentTarget.value) {
            option.isSelected = e.currentTarget.checked;
          }
        });
      }
    });
    setFiltersState({ filters: updatedFilters.filters });
  };

  const onLastPostChange = (name, date) => {
    const updatedFilters = { ...filtersState };
    updatedFilters.filters.forEach(filter => {
      if (filter.name === 'last-post') {
        filter[name] = date;
      }
    });
    setFiltersState({ filters: updatedFilters.filters });
  };

  const onDateJoinedChange = (name, date) => {
    const updatedFilters = { ...filtersState };
    updatedFilters.filters.forEach(filter => {
      if (filter.name === 'date-joined') {
        filter[name] = date;
      }
    });
    setFiltersState({ filters: updatedFilters.filters });
  };

  const filters = [
    {
      title: <String id="community_admin_role_tab" />,
      type: 'radio',
      name: 'role',
      onChange: onChangeRoleFilter,
      selectedValue: null,
      options: [
        {
          name: 'All',
          id: 'All',
          label: 'All',
          value: null,
          isSelected: true,
        },
        {
          name: 'Member',
          id: 'Member',
          label: <String id="community_admin_filter_member" />,
          value: 'member',
          isSelected: false,
        },
        {
          name: 'Admin',
          id: 'Admin',
          label: <String id="admin_cms_admin" />,
          value: 'admin',
          isSelected: false,
        },
        {
          name: 'Editor',
          id: 'Editor',
          label: <String id="admin_cms_editor" />,
          value: 'editor',
          isSelected: false,
        },
        {
          name: 'Trusted member',
          id: 'Trusted member',
          label: <String id="admin_cms_contributor" />,
          value: 'contributor',
          isSelected: false,
        },
      ],
    },
    {
      title: <String id="community_admin_status_tab" />,
      type: 'radio',
      name: 'status',
      onChange: onChangeStatus,
      selectedValue: null,
      options: [
        {
          name: 'All',
          id: 'AllMembers',
          label: 'All',
          value: null,
          isSelected: true,
        },
        {
          name: 'Active',
          id: 'Active',
          label: <String id="community_admin_filter_status_active" />,
          value: 'default',
          isSelected: false,
        },
        {
          name: 'Deleted',
          id: 'Deleted',
          label: <String id="community_admin_filter_status_deleted" />,
          value: 'deleted',
          isSelected: false,
        },
        {
          name: 'Pending',
          id: 'Pending',
          label: <String id="community_admin_filter_status_pending" />,
          value: 'pending',
          isSelected: false,
        },
        {
          name: 'Archived',
          id: 'Archived',
          label: <String id="community_admin_filter_status_archived" />,
          value: 'archived',
          isSelected: false,
        },
        // {
        //   name: 'Suspended',
        //   id: 'Suspended',
        //   label: <String id="community_admin_filter_suspended" />,
        //   value: 'suspended',
        //   isChecked: false,
        // },
      ],
    },
    {
      title: <String id="community_admin_last_post_tab" />,
      type: 'date',
      name: 'last-post',
      startDateLabel: 'From',
      endDateLabel: 'To',
      startDate: null,
      endDate: null,
      onChange: onLastPostChange,
    },
    {
      title: <String id="community_admin_filter_date_joined" />,
      type: 'date',
      name: 'date-joined',
      startDateLabel: 'From',
      endDateLabel: 'To',
      startDate: null,
      endDate: null,
      onChange: onDateJoinedChange,
    },
  ];
  const [filtersState, setFiltersState] = useState({
    filters,
  });

  const onSearchSubmit = searchInput => {
    setUsersContent({
      ...usersContent,
      filters: searchInput !== '' ? { search: searchInput } : {},
      page: 1,
    });
    userData.refetch();
  };

  return (
    <AdminLayout location={context.location}>
      <SEO title="Area" />
      <AdvanceFilters
        title={<String id="admin_cms_members_title" />}
        onSubmitFilters={onApplyFilters}
        filters={filtersState.filters}
        onSearchSubmit={onSearchSubmit}
      />
      <Table
        nextPage={onNextPage}
        prevPage={onPrevPage}
        changeRowPerPage={onChangeLimitRow}
        totalCount={usersContent.totalCount}
        currentCount={usersContent.currentCount}
        loading={
          userData.loading ||
          deleteUsersData.loading ||
          changeRoleData.loading ||
          changeStatusData.loading
        }
        rowData={usersList}
        columns={usersContent.columns}
        maxPage={usersContent.maxPage}
        currentPage={usersContent.page}
        hasOptions
        bulkActions={bulkActions}
      />

      <Modal isShowing={isShowing}>
        <Heading level="h3" css={{ marginBottom: '$16', lineHeight: '1.3em' }}>
          {currentSelectedUsers.isDeleted ||
          currentSelectedUsers.isSuspended ? (
            <String id="admin_member_bring_back_title" />
          ) : (
            <String id="admin_member_delete_title" />
          )}
        </Heading>
        <Button
          actionType="onclick"
          colorTheme="normal"
          onClick={() => {
            currentSelectedUsers.isDeleting
              ? onDeleteUser()
              : onBringBackUser();
          }}
          label="Confirm"
          type="normal"
          css={{ marginRight: '$32' }}
        />
        <Button
          actionType="onclick"
          colorTheme="gray"
          onClick={toggle}
          label="Cancel"
          type="normal"
        />
      </Modal>
    </AdminLayout>
  );
};

export default Members;
