import {
  AmbassadorWrapper,
  ContentWrapper,
  ContentWrapperFooter,
  TitleStyled,
  ImageStyled,
  ImageWrapper,
  StyledSvg,
  ThumbnailContentType,
  ThumbnailLevelWrapper,
  ThumbnailPendingWrapper,
  ThumbnailWrapper,
} from './Thumbnail.styled';

import Button from '@components/atoms/button/Button';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import SvgReactions from '@components/atoms/svgReactions/SvgReactions';
import { Text } from '@components/atoms/text/Text';
import ThumbnailAuthoring from './thumbnailAuthoring/ThumbnailAuthoring';
import ThumbnailBadgeList from '../thumbnail/thumbnailBadgeList/ThumbnailBadgeList';
import ThumbnailMenu from './thumbnailMenu/ThumbnailMenu';
import { USERCONTENT_REACTION } from '@src/apollo/mutation';
import { useIsMobile } from '@src/utils/useIsMobile';
import { useMutation } from '@apollo/client';
import useModal from '@components/atoms/modal/useModal';
import Modal from '@components/atoms/modal/Modal';
import PublishNotification from '@components/molecules/publishNotification/PublishNotification';
import AuthContext from '@src/context/AuthContext';

const TYPES_WITH_TOOLS = ['tutorial', 'asset', 'tutorial_series'];
function Thumbnail(props) {
  const {
    type,
    tutorialLevel,
    ambassadorType,
    authoring,
    menuActions,
    className,
    status,
    title,
    imageUrl,
    srcSet,
    ambassadorTitle,
    datePosted,
    url,
    id,
    urlTarget,
    seriesCount,
    showLikeButton,
    isLiked,
    isFeatured,
    isMini,
    isInGroupPage,
    isHalf,
    displayType,
    css,
  } = props;

  const isMobile = useIsMobile();
  const authContext = useContext(AuthContext);
  const [toggleLike, toggleLikeData] = useMutation(USERCONTENT_REACTION);
  const [isLikedState, setIsLikedState] = useState(isLiked);
  
  const { isShowing: isShowingJoin, toggle: toggleJoin } = useModal();

  const getSrcSet = () => {
    if (imageUrl?.includes('null')) {
      const placeholderImg = {
        src: '/images/area_default_cover.jpg',
        source: [
          {
            src: '/images/area_default_cover.jpg',
            media: '(min-width: 769px)',
          },
        ],
      };
      return placeholderImg;
    }

    if (srcSet === undefined || srcSet.length === 0) {
      const noSrcSet = {
        src: imageUrl,
        source: [
          {
            srcset: imageUrl,
            media: '(min-width: 769px)',
          },
        ],
      };
      return noSrcSet;
    }

    const srcSetFeaturedData = {
      src: imageUrl,
      source: [
        {
          srcset: `${CDN_URL}${isFeatured ? srcSet[0].path : srcSet[2].path}`,
          media: '(min-width: 769px)',
        },
        {
          srcset: `${CDN_URL}${isFeatured ? srcSet[1].path : srcSet[1].path}`,
          media: '(max-width: 768px)',
        },
      ],
    };
    return srcSetFeaturedData;
  };

  useEffect(() => {
    setIsLikedState(isLiked);
  }, [isLiked]);

  useEffect(() => {
    if (toggleLikeData?.called && toggleLikeData?.data?.userContentReaction) {
      setIsLikedState(toggleLikeData?.data?.userContentReaction?.isLiked);
    }
  }, [toggleLikeData]);

  return (
    <ThumbnailWrapper
      isMini={isMini}
      isFeatured={isFeatured}
      className={className}
      isMobile={isMobile}
      css={css}
    >
      {!!url && <Link to={url} target={urlTarget} className="thumbnail-link" />}
      <ImageWrapper
        type={type}
        isHalf={isHalf}
        isAllRounded={
          type === 'ambassador' ? true : type === 'artwork' ? true : false
        }
        isMini={isMini}
        className={
          isMini && type !== 'artwork' && type !== 'ambassador'
            ? 'mini-image'
            : ''
        }
      >
        {type !== 'ambassador' && (
          <>
            <ThumbnailAuthoring
              hasMenu={menuActions && menuActions.length >= 1}
              author={authoring?.author}
              profilePicture={authoring.profilePicture}
              type={type}
              isMini={isMini}
              title={title}
            />
          </>
        )}
        {type === 'ambassador' && (
          <AmbassadorWrapper isMini={isMini}>
            <div className="ambassador-wrapper-header">
              {ambassadorType === 'moty' ? (
                <Text
                  as="p"
                  size={isFeatured ? '18' : '16'}
                  className="ambassador-wrapper-header-type"
                >
                  <String id="thumbnail_moty" />
                </Text>
              ) : (
                <>
                  <Text
                    as="p"
                    size={isFeatured ? '18' : '16'}
                    className="ambassador-wrapper-header-type"
                  >
                    <String id="thumbnail_motm" />
                  </Text>
                </>
              )}
              <span>{datePosted}</span>
            </div>
            <Text as={isFeatured ? 'h2' : 'h3'}>{title}</Text>
          </AmbassadorWrapper>
        )}
        {showLikeButton && id !== undefined && (
          <Button
            type="circle"
            actionType="onclick"
            onClick={() => {
              if (!authContext?.isOnboarded) {
                toggleJoin();
              } else {
                setIsLikedState(!isLikedState);
                toggleLike({
                  variables: {
                    userContentId: id,
                    input: {
                      reaction: 'like',
                      action: !isLikedState ? 'add' : 'remove',
                    },
                  },
                });
              }
            }}
            css={{
              position: 'absolute',
              top: isMini ? '$8' : '$16',
              left: isMini ? '$8' : '$24',
            }}
            colorTheme={isLikedState ? 'reactions' : 'reactionsInactive'}
            children={
              <SvgReactions
                label={isLikedState ? 'love' : 'love-stroke'}
                active={isLikedState}
                inactive={!isLikedState}
              />
            }
          />
        )}
        {type !== 'ambassador' && displayType && (
          <ThumbnailContentType isMini={isMini}>
            <Svg
              icon={type}
              css={{
                height: '$16',
                width: '$16',
                fill: '$white',
              }}
            />
            <span>{<String id={`thumbnail_${type}`} />}</span>
          </ThumbnailContentType>
        )}
        {status && status === 'pending' && (
          <ThumbnailPendingWrapper>
            <Svg icon="clock" className="pending-icon" />
            <Text as="p" size="12">
              <String id="thumbnail_pending_user_content" />
            </Text>
          </ThumbnailPendingWrapper>
        )}
        {menuActions && menuActions.length >= 1 && (
          <ThumbnailMenu actions={menuActions} />
        )}
        <ImageStyled
          className={
            type === 'ambassador'
              ? 'ambassador thumbnail-cover'
              : 'thumbnail-cover'
          }
          src={getSrcSet()}
          alt={title}
          isBackground
          isAllRounded={
            type === 'ambassador' ? true : type === 'artwork' ? true : false
          }
        />
      </ImageWrapper>
      {type !== 'artwork' && type !== 'ambassador' && (
        <ContentWrapper isFeatured={isFeatured} isMini={isMini} isHalf={isHalf}>
          <div className="content-wrapper-inner">
            <ContentWrapperFooter isMini={isMini}>
              <div>
                <Text as="p" className="thumbnail-label">
                  {/* // <String id={`thumbnail_${type}`} /> */}
                  {datePosted}
                </Text>
                {!isFeatured && (
                  <TitleStyled as="h6" isInGroupPage={isInGroupPage}>
                    {title}
                  </TitleStyled>
                )}
                {isFeatured && <Text as="h2">{title}</Text>}
              </div>

              {!isInGroupPage &&
                (type === 'tutorial' || type === 'tutorial_series') && (
                  <ThumbnailLevelWrapper>
                    {(type === 'tutorial' ||
                      (type === 'tutorial_series' && seriesCount >= 1)) && (
                      <StyledSvg
                        icon={
                          type === 'tutorial'
                            ? 'thumbnail-level'
                            : 'thumbnail-series'
                        }
                      />
                    )}
                    {type === 'tutorial' && (
                      <Text size="12">
                        <String
                          id={`thumbnail_tutorial_level_${tutorialLevel}`}
                        />
                      </Text>
                    )}
                    {type === 'tutorial_series' && seriesCount >= 1 && (
                      <Text size="12">
                        {`${seriesCount} `}
                        <String id="thumbnail_tutorial_series_title" />
                      </Text>
                    )}
                  </ThumbnailLevelWrapper>
                )}
            </ContentWrapperFooter>
          </div>
        </ContentWrapper>
      )}
      <Modal isShowing={isShowingJoin} hide={toggleJoin} hasImageHeader>
        {
          <PublishNotification
            type={'onboarding'}
            url={`/m/${authContext.userSlug}`}
          />
        }
      </Modal>
    </ThumbnailWrapper>
  );
}

Thumbnail.propTypes = {
  type: PropTypes.oneOf([
    'artwork',
    'article',
    'tutorial',
    'tutorial_series',
    'asset',
    'ambassador',
  ]).isRequired,
  status: PropTypes.oneOf(['pending', 'draft', 'publish']),
  tutorialLevel: PropTypes.oneOf(['beginner', 'intermediate', 'expert']),
  ambassadorType: PropTypes.oneOf(['motm', 'moty']),

  authoring: PropTypes.shape({
    profilePicture: PropTypes.string,
    author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  }),

  menuActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onActionClick: PropTypes.func.isRequired,
      svgIcon: PropTypes.string,
    })
  ),

  css: PropTypes.object,
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  ambassadorTitle: PropTypes.string,
  datePosted: PropTypes.string,
  url: PropTypes.string,
  urlTarget: PropTypes.string,
  seriesCount: PropTypes.number,
  id: PropTypes.number,
  showLikeButton: PropTypes.bool,
  isLiked: PropTypes.bool,
  isFeatured: PropTypes.bool,
  isMini: PropTypes.bool,
  isInGroupPage: PropTypes.bool,
  isHalf: PropTypes.bool,
  displayType: PropTypes.bool,
};

Thumbnail.defaultProps = {
  className: '',
  authoring: {},

  menuActions: [],
  tutorialLevel: undefined,
  ambassadorType: undefined,
  ambassadorTitle: undefined,
  datePosted: undefined,
  url: undefined,
  id: undefined,
  css: {},
  urlTarget: undefined,
  seriesCount: 0,
  showLikeButton: false,
  isLiked: false,
  isFeatured: false,
  isMini: false,
  isInGroupPage: false,
  isHalf: false,
  displayType: false,
};

export default Thumbnail;
